/* eslint-disable */
// @ts-nocheck
type Maybe<T> = T | null

/** All built-in and custom scalars, mapped to their actual values */
type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AdminCohortResult = {
  __typename?: 'AdminCohortResult'
  cohorts?: Maybe<Array<Cohort>>
  pages: Scalars['Int']
}

export type AdminProject = {
  __typename?: 'AdminProject'
  id: Scalars['ID']
  title: Scalars['String']
  xp: Scalars['Int']
  deadline: Scalars['String']
  company: Company
  status: ProjectStatus
  isPaid: Scalars['Boolean']
  totalApplicants: Scalars['Int']
  apprentice?: Maybe<User>
}

export type AdminProjectResult = {
  __typename?: 'AdminProjectResult'
  totalPages: Scalars['Int']
  projects?: Maybe<Array<AdminProject>>
}

export enum Agreements {
  Conduct = 'CONDUCT',
  Mentor = 'MENTOR',
  Privacy = 'PRIVACY',
  Website = 'WEBSITE'
}

export type ApplyProjectInput = {
  cover?: Maybe<Scalars['String']>
  projectId: Scalars['ID']
}

export type Attachment = {
  __typename?: 'Attachment'
  id: Scalars['ID']
  label?: Maybe<Scalars['String']>
  uri: Scalars['String']
}

type AttachmentInput = {
  label?: Maybe<Scalars['String']>
  uri: Scalars['String']
}

export type AuthResult = {
  __typename?: 'AuthResult'
  token: Scalars['String']
  user: User
}

export type Bank = {
  __typename?: 'Bank'
  details: BankDetails
  iban: Scalars['String']
  country: Scalars['String']
  name: Scalars['String']
  swiftCode: Scalars['String']
  address: Scalars['String']
  currency: Scalars['String']
}

type BankDetails = {
  __typename?: 'BankDetails'
  firstName: Scalars['String']
  lastName: Scalars['String']
  phonePrefix: Scalars['String']
  phone: Scalars['String']
  beneficiaryAddress: Scalars['String']
}

type BankDetailsInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  phonePrefix: Scalars['String']
  phone: Scalars['String']
  beneficiaryAddress: Scalars['String']
}

export type BankInput = {
  details: BankDetailsInput
  iban: Scalars['String']
  country: Scalars['String']
  name: Scalars['String']
  swiftCode: Scalars['String']
  address: Scalars['String']
  currency: Scalars['String']
}

type Billing = {
  __typename?: 'Billing'
  id: Scalars['ID']
  isPaid: Scalars['Boolean']
  items: Array<BillingItem>
  total: Scalars['Int']
  createdAt: Scalars['String']
  dueAt: Scalars['String']
}

type BillingItem = {
  __typename?: 'BillingItem'
  name: Scalars['String']
  price: Scalars['Int']
  quantity: Scalars['Int']
  section: BillingItemSection
}

enum BillingItemSection {
  Base = 'BASE',
  Deliverable = 'DELIVERABLE',
  Targeting = 'TARGETING',
  Extra = 'EXTRA'
}

export type CancelSubscriptionInput = {
  companyId: Scalars['ID']
  reason: Scalars['String']
}

export type Cashout = {
  __typename?: 'Cashout'
  id: Scalars['ID']
  reference?: Maybe<Scalars['String']>
  bank?: Maybe<Bank>
  user: User
  amount: Scalars['String']
  total: Scalars['String']
  currency: Scalars['String']
  fee?: Maybe<Scalars['String']>
  xp?: Maybe<Scalars['Int']>
  status: CashoutStatus
  requestedAt: DateItem
  completedAt?: Maybe<DateItem>
}

export type CashoutInput = {
  id?: Maybe<Scalars['ID']>
  reference?: Maybe<Scalars['String']>
  bank?: Maybe<BankInput>
  status: CashoutStatus
  amount?: Maybe<Scalars['String']>
  total?: Maybe<Scalars['String']>
  currency?: Maybe<Scalars['String']>
  fee?: Maybe<Scalars['String']>
  xp?: Maybe<Scalars['Int']>
}

export enum CashoutStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

export type Category = {
  __typename?: 'Category'
  id: Scalars['ID']
  color?: Maybe<Scalars['String']>
  name: Scalars['String']
  parent?: Maybe<Scalars['String']>
  slug: Scalars['String']
  tagline?: Maybe<Scalars['String']>
  type: CategoryType
  verified: Scalars['Boolean']
}

export enum CategoryType {
  Industry = 'INDUSTRY',
  Other = 'OTHER',
  Tools = 'TOOLS',
  Project = 'PROJECT',
  Proficiency = 'PROFICIENCY'
}

export type Certificate = {
  __typename?: 'Certificate'
  id: Scalars['ID']
  code: Scalars['String']
  category?: Maybe<Scalars['String']>
  company: CertificateCompany
  isPublic: Scalars['Boolean']
  project: CertificateProject
  projectReference?: Maybe<Project>
  preview: Scalars['Boolean']
  referenceImage?: Maybe<Scalars['String']>
  rating: Scalars['String']
  uri: Scalars['String']
  user: CertificateUser
  createdAt: Scalars['String']
}

type CertificateCompany = {
  __typename?: 'CertificateCompany'
  logo: Scalars['String']
  name: Scalars['String']
}

type CertificateProject = {
  __typename?: 'CertificateProject'
  description: Scalars['String']
  skills?: Maybe<Array<Scalars['String']>>
  title: Scalars['String']
}

type CertificateUser = {
  __typename?: 'CertificateUser'
  firstName: Scalars['String']
  lastName: Scalars['String']
}

export type Challenge = {
  __typename?: 'Challenge'
  id: Scalars['ID']
  beginner?: Maybe<ChallengeDetails>
  categories?: Maybe<Array<Category>>
  companies?: Maybe<Array<DummyCompany>>
  isCustom: Scalars['Boolean']
  proficient?: Maybe<ChallengeDetails>
  skilled?: Maybe<ChallengeDetails>
  skills: Array<Skill>
  uploader: User
  signupCode?: Maybe<Array<Scalars['String']>>
}

type ChallengeContent = {
  __typename?: 'ChallengeContent'
  title: Scalars['String']
  content: Scalars['String']
}

type ChallengeContentInput = {
  title: Scalars['String']
  content: Scalars['String']
}

export type ChallengeDetails = {
  __typename?: 'ChallengeDetails'
  assignment?: Maybe<Scalars['String']>
  brief?: Maybe<Scalars['String']>
  content?: Maybe<Array<ChallengeContent>>
  duration?: Maybe<ChallengeDuration>
  evaluation?: Maybe<Array<ChallengeEvaluation>>
  guidelines?: Maybe<Scalars['String']>
  hoursDuration?: Maybe<Scalars['Int']>
  resources?: Maybe<Array<Resource>>
  subskills?: Maybe<Array<Skill>>
  title: Scalars['String']
  xp: Scalars['Int']
}

type ChallengeDetailsInput = {
  assignment?: Maybe<Scalars['String']>
  brief?: Maybe<Scalars['String']>
  content?: Maybe<Array<ChallengeContentInput>>
  duration?: Maybe<ChallengeDurationInput>
  evaluation?: Maybe<Array<ChallengeEvaluationInput>>
  guidelines?: Maybe<Scalars['String']>
  hoursDuration?: Maybe<Scalars['Int']>
  resources?: Maybe<Array<Scalars['ID']>>
  subskills?: Maybe<Array<Scalars['ID']>>
  title?: Maybe<Scalars['String']>
  xp?: Maybe<Scalars['Int']>
}

export type ChallengeDuration = {
  __typename?: 'ChallengeDuration'
  startDate: DateItem
  endDate: DateItem
}

type ChallengeDurationInput = {
  startDate: DateItemInput
  endDate: DateItemInput
}

type ChallengeEvaluation = {
  __typename?: 'ChallengeEvaluation'
  content: Scalars['String']
  feedback?: Maybe<Scalars['String']>
  scoreOne: Scalars['String']
  scoreTwo: Scalars['String']
  scoreThree: Scalars['String']
  scoreFour: Scalars['String']
  scoreFive: Scalars['String']
  title: Scalars['String']
}

type ChallengeEvaluationInput = {
  content: Scalars['String']
  scoreOne: Scalars['String']
  scoreTwo: Scalars['String']
  scoreThree: Scalars['String']
  scoreFour: Scalars['String']
  scoreFive: Scalars['String']
  title: Scalars['String']
}

type ChallengeInput = {
  id?: Maybe<Scalars['ID']>
  categories?: Maybe<Array<Scalars['ID']>>
  companies?: Maybe<Array<Scalars['ID']>>
  beginner: ChallengeDetailsInput
  proficient: ChallengeDetailsInput
  skills: Array<Scalars['ID']>
  skilled: ChallengeDetailsInput
  isCustom?: Maybe<Scalars['Boolean']>
  signupCode?: Maybe<Array<Maybe<Scalars['String']>>>
}

type ChallengeSessionInput = {
  page?: Maybe<Scalars['Int']>
  level?: Maybe<SkillRank>
  skill?: Maybe<Scalars['ID']>
}

type ChallengeTaker = {
  __typename?: 'ChallengeTaker'
  challenge: Scalars['String']
  level: SkillRank
  user: User
  status: UserChallengeStatus
}

export type Chat = {
  __typename?: 'Chat'
  id: Scalars['ID']
  key: Scalars['String']
  reference: MessageReference
  referenceID: Scalars['String']
  threads?: Maybe<Array<ChatThread>>
}

export type ChatDataProps = {
  __typename?: 'ChatDataProps'
  users?: Maybe<Array<User>>
  unread?: Maybe<Array<UnreadProps>>
}

export type ChatGeneratorData = {
  __typename?: 'ChatGeneratorData'
  type: MessageReference
  cohort?: Maybe<Scalars['String']>
  project?: Maybe<Scalars['String']>
  mentor?: Maybe<Scalars['String']>
  student?: Maybe<Scalars['String']>
  professional?: Maybe<Scalars['String']>
  workspaces?: Maybe<Array<CohortWorkspace>>
}

export type ChatGeneratorInput = {
  threads: Array<ChatThreadInput>
  reference: MessageReference
  referenceID: Scalars['String']
}

type ChatHead = {
  __typename?: 'ChatHead'
  user?: Maybe<User>
  userID: Scalars['String']
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
}

export type ChatHeadData = {
  __typename?: 'ChatHeadData'
  photo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  user?: Maybe<User>
}

export type ChatHeadInput = {
  user?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
}

export type ChatMessage = {
  __typename?: 'ChatMessage'
  authorId: Scalars['String']
  author?: Maybe<User>
  message: Scalars['String']
  createdAt: Scalars['String']
  readers?: Maybe<Array<Scalars['String']>>
  type: MessageType
}

export type ChatThread = {
  __typename?: 'ChatThread'
  id: Scalars['ID']
  category: ChatType
  prefix: Scalars['String']
  heads?: Maybe<Array<ChatHead>>
  type: ChatThreadType
  messages?: Maybe<Array<ChatMessage>>
}

export type ChatThreadInput = {
  category: ChatType
  prefix: Scalars['String']
  role?: Maybe<Scalars['String']>
  photo?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  type?: Maybe<ChatThreadType>
  chatHeads?: Maybe<Array<ChatHeadInput>>
}

export enum ChatThreadType {
  Single = 'SINGLE',
  Group = 'GROUP'
}

export enum ChatType {
  Project = 'PROJECT',
  Mentor = 'MENTOR',
  Cohort = 'COHORT'
}

export enum ClearNotificationType {
  Qa = 'QA',
  Update = 'UPDATE'
}

export type ClearTaskNotificationInput = {
  projectId: Scalars['ID']
  task: Scalars['ID']
  type: ClearNotificationType
}

export type Cohort = {
  __typename?: 'Cohort'
  id: Scalars['ID']
  category: Category
  chats?: Maybe<Array<CohortChat>>
  company: Company
  competition: Scalars['Boolean']
  competitionRsvp: CompetitionRsvp
  proficiency: Category
  project: Project
  rewardXP: Scalars['Int']
  startDate: DateItem
  endDate: DateItem
  enrollBy: DateItem
  createdAt: DateItem
  modules: Array<CohortModule>
  participants: Array<CohortParticipant>
  groups: Array<CohortGroup>
  userProject?: Maybe<Scalars['String']>
}

export type CohortApplicationAnswerInput = {
  question: Scalars['ID']
  answer: Scalars['String']
}

enum CohortApplicationStatus {
  Passed = 'PASSED',
  Reconfirm = 'RECONFIRM'
}

export type CohortChat = {
  __typename?: 'CohortChat'
  id: Scalars['ID']
  author: User
  message: Scalars['String']
  type: ProjectChatType
  createdAt: Scalars['String']
  read: Scalars['Boolean']
  readers?: Maybe<Array<Scalars['String']>>
  reference: Scalars['String']
}

type CohortGroup = {
  __typename?: 'CohortGroup'
  name: Scalars['String']
  users: Array<User>
}

type CohortMeeting = {
  __typename?: 'CohortMeeting'
  duration: Scalars['String']
  link: Scalars['String']
  startDate: DateItem
  endDate: DateItem
}

export type CohortModule = {
  __typename?: 'CohortModule'
  id: Scalars['ID']
  name: Scalars['String']
  notification: CohortNotification
  program: Array<CohortProgram>
  tasks?: Maybe<Array<ProjectTask>>
  recording?: Maybe<Scalars['String']>
}

type CohortNotification = {
  __typename?: 'CohortNotification'
  tenmin: Scalars['Boolean']
  oneday: Scalars['Boolean']
  recording: Scalars['Boolean']
}

type CohortParticipant = {
  __typename?: 'CohortParticipant'
  user: User
  status: CohortParticipantStatus
  rating: Scalars['String']
}

export enum CohortParticipantStatus {
  Failed = 'FAILED',
  Passed = 'PASSED',
  Withdrawn = 'WITHDRAWN',
  Completed = 'COMPLETED'
}

type CohortProgram = {
  __typename?: 'CohortProgram'
  description: Scalars['String']
  title: Scalars['String']
  meeting?: Maybe<CohortMeeting>
  startDate: DateItem
  shortDescription: Scalars['String']
  ics?: Maybe<Scalars['String']>
}

export type CohortProgramResult = {
  __typename?: 'CohortProgramResult'
  description: Scalars['String']
  title: Scalars['String']
  meeting?: Maybe<CohortMeeting>
  startDate: DateItem
  shortDescription: Scalars['String']
  ics?: Maybe<Scalars['String']>
  status?: Maybe<CohortProgramResultStatus>
}

export enum CohortProgramResultStatus {
  Pending = 'PENDING',
  Started = 'STARTED',
  Completed = 'COMPLETED'
}

export type CohortProgressResult = {
  __typename?: 'CohortProgressResult'
  cohort: Scalars['String']
  progress: Scalars['String']
}

export type CohortQuestion = {
  __typename?: 'CohortQuestion'
  id: Scalars['ID']
  title: Scalars['String']
  answers: Array<CohortQuestionAnswer>
  type: CohortQuestionType
}

type CohortQuestionAnswer = {
  __typename?: 'CohortQuestionAnswer'
  point: Scalars['Int']
  title: Scalars['String']
}

enum CohortQuestionType {
  Range = 'RANGE',
  Single = 'SINGLE'
}

export enum CohortStatus {
  Pending = 'PENDING',
  Active = 'ACTIVE',
  Done = 'DONE'
}

export type CohortWorkspace = {
  __typename?: 'CohortWorkspace'
  user: User
  updates: Scalars['Int']
  projectId: Scalars['String']
}

type CommunityNotification = {
  __typename?: 'CommunityNotification'
  category: Category
  isWeekly: Scalars['Boolean']
  isMonthly: Scalars['Boolean']
}

/**
 * @deprecated This code is deprecated.
 */
export type Company = {
  __typename?: 'Company'
  id: Scalars['ID']
  brief?: Maybe<Scalars['String']>
  domains: Array<Scalars['String']>
  dummyCompany?: Maybe<DummyCompany>
  industry?: Maybe<Array<Category>>
  isDemo: Scalars['Boolean']
  isDummy: Scalars['Boolean']
  location?: Maybe<Scalars['String']>
  logo: Scalars['String']
  members?: Maybe<Array<CompanyMember>>
  meta?: Maybe<CompanyMeta>
  name: Scalars['String']
  slug: Scalars['String']
  signupCode?: Maybe<Scalars['String']>
  subscription?: Maybe<CompanySubscription>
  createdAt: Scalars['String']
}

type CompanyMedia = {
  __typename?: 'CompanyMedia'
  url: Scalars['String']
  isPrimary: Scalars['Boolean']
  isVideo?: Maybe<Scalars['Boolean']>
}

type CompanyMember = {
  __typename?: 'CompanyMember'
  user?: Maybe<User>
  role: CompanyRole
  createdAt: Scalars['String']
}

type CompanyMeta = {
  __typename?: 'CompanyMeta'
  employees: Scalars['String']
  website: Scalars['String']
  location: Array<Scalars['String']>
  facebook: Scalars['String']
  twitter: Scalars['String']
  instagram: Scalars['String']
  linkedin: Scalars['String']
  url: Scalars['String']
  media?: Maybe<Array<CompanyMedia>>
}

type CompanyPricing = {
  __typename?: 'CompanyPricing'
  deadline: Scalars['Int']
  deliverablePer: Scalars['Int']
  expertPer: Scalars['Int']
  onLocationPer: Scalars['Int']
  participantPer: Scalars['Int']
  project: Scalars['Int']
  shareProfiles: Scalars['Int']
  targetDegreePer: Scalars['Int']
  targetNationalityPer: Scalars['Int']
  targetSchoolPer: Scalars['Int']
  targetSkillPer: Scalars['Int']
}

export enum CompanyRole {
  Manager = 'MANAGER',
  Member = 'MEMBER',
  Owner = 'OWNER'
}

type CompanySubscription = {
  __typename?: 'CompanySubscription'
  plan: CompanySubscriptionPlan
  isAutoDeduct: Scalars['Boolean']
  trials: Scalars['Int']
  isPaid: Scalars['Boolean']
  startedAt: Scalars['String']
  expiration: Scalars['String']
  previousPlan?: Maybe<CompanySubscriptionPlan>
}

export enum CompanySubscriptionPlan {
  Free = 'FREE',
  Basic = 'BASIC',
  Pro = 'PRO',
  PayPerProject = 'PAY_PER_PROJECT',
  Custom = 'CUSTOM'
}

enum CompetitionRsvp {
  All = 'ALL',
  Graduates = 'GRADUATES'
}

export type CompletedProject = {
  __typename?: 'CompletedProject'
  id: Scalars['ID']
  project?: Maybe<Project>
  rating: Scalars['String']
  skills?: Maybe<Array<Skill>>
  createdAt: Scalars['String']
}

export type Compliment = {
  __typename?: 'Compliment'
  id: Scalars['ID']
  icon: Scalars['String']
  name: Scalars['String']
  type: ComplimentType
}

export enum ComplimentType {
  Mentor = 'MENTOR',
  User = 'USER'
}

export enum CookieType {
  Corporate = 'CORPORATE',
  Student = 'STUDENT'
}

type CorporateSignupInput = {
  firstName: Scalars['String']
  lastName: Scalars['String']
  company: Scalars['String']
  phonePrefix: Scalars['String']
  phoneCountryCode: Scalars['String']
  phone: Scalars['String']
  email: Scalars['String']
  password: Scalars['String']
  signupCode?: Maybe<Scalars['String']>
}

type CreatePaymentMethodInput = {
  cvv: Scalars['Int']
  expiryMonth: Scalars['Int']
  expiryYear: Scalars['Int']
  number: Scalars['String']
  type: PaymentMethodType
}

type CreateQuestionInput = {
  attachments?: Maybe<Array<AttachmentInput>>
  body: Scalars['String']
  categories: Array<Scalars['ID']>
  language: QuestionLanguage
  type: SessionType
}

type CreateResourceInput = {
  id?: Maybe<Scalars['ID']>
  category?: Maybe<Array<Scalars['ID']>>
  description?: Maybe<Scalars['String']>
  file?: Maybe<Scalars['String']>
  link?: Maybe<Scalars['String']>
  topic?: Maybe<Scalars['ID']>
  type?: Maybe<Scalars['ID']>
  name: Scalars['String']
}

type DateFilter = {
  __typename?: 'DateFilter'
  active: Scalars['Boolean']
  name: Scalars['String']
  value: Scalars['String']
  type: Scalars['String']
}

type DateItem = {
  __typename?: 'DateItem'
  date: Scalars['String']
  timezone: Scalars['String']
}

type DateItemInput = {
  date: Scalars['String']
  timezone: Scalars['String']
}

type Degree = {
  __typename?: 'Degree'
  id: Scalars['ID']
  majors: Array<Scalars['String']>
  name: Scalars['String']
}

export type DeleteProgressInput = {
  projectId: Scalars['ID']
  progressId: Scalars['ID']
}

export type DepthAnalytics = {
  __typename?: 'DepthAnalytics'
  users: UserAnalyticsResult
  activeReview: Array<UserChallenge>
  purchasedCredits: Array<User>
  redeemRequest: Array<User>
  session: DepthSessionAnalytics
  unfinishedPersonalityTest: Array<User>
  referred: Array<User>
}

type DepthSessionAnalytics = {
  __typename?: 'DepthSessionAnalytics'
  active: Array<Session>
  inactive: Array<Session>
}

export type DrawerHeaderProps = {
  __typename?: 'DrawerHeaderProps'
  title: Scalars['String']
  isFull?: Maybe<Scalars['Boolean']>
  key?: Maybe<Scalars['String']>
}

export type DropdownItem = {
  __typename?: 'DropdownItem'
  label: Scalars['String']
  value: Scalars['String']
}

export type DropdownItemNumber = {
  __typename?: 'DropdownItemNumber'
  label: Scalars['String']
  value: Scalars['Int']
}

type DummyCompany = {
  __typename?: 'DummyCompany'
  id: Scalars['ID']
  brief: Scalars['String']
  files?: Maybe<Array<Resource>>
  industry?: Maybe<Array<Category>>
  logo: Scalars['String']
  name: Scalars['String']
}

type DummyCompanyQueryInput = {
  challenge: Scalars['ID']
  level: SkillRank
  skill: Scalars['ID']
}

type Endorsement = {
  __typename?: 'Endorsement'
  id: Scalars['ID']
  requestedBy: User
  requestedTo: User
  skills?: Maybe<Array<Skill>>
}

type Field = {
  __typename?: 'Field'
  id: Scalars['ID']
  name: Scalars['String']
  isVerified: Scalars['Boolean']
}

export type FileProps = {
  __typename?: 'FileProps'
  name: Scalars['String']
  uri: Scalars['String']
  isLink?: Maybe<Scalars['Boolean']>
}

export type Filters = {
  __typename?: 'Filters'
  statuses?: Maybe<Array<StatusesFilter>>
  dates?: Maybe<Array<DateFilter>>
}

type ForumActionInput = {
  type: ForumInputType
  action: ForumActionType
  react?: Maybe<ForumReactType>
  reportReason?: Maybe<ForumReportReason>
  id: Scalars['ID']
}

export enum ForumActionType {
  Helpful = 'HELPFUL',
  Bookmark = 'BOOKMARK',
  Follow = 'FOLLOW',
  React = 'REACT',
  Report = 'REPORT',
  Delete = 'DELETE'
}

export type ForumComment = {
  __typename?: 'ForumComment'
  id: Scalars['ID']
  comment: Scalars['String']
  user: User
  createdAt: DateItem
  helped?: Maybe<Array<User>>
  recipient?: Maybe<User>
}

export enum ForumFilter {
  Bookmark = 'BOOKMARK',
  Unanswered = 'UNANSWERED',
  Helpful = 'HELPFUL',
  Trending = 'TRENDING'
}

export enum ForumInputType {
  Comment = 'COMMENT',
  Post = 'POST'
}

type ForumPollOption = {
  __typename?: 'ForumPollOption'
  id: Scalars['ID']
  label: Scalars['String']
  votees?: Maybe<Array<Scalars['String']>>
}

export type ForumPost = {
  __typename?: 'ForumPost'
  id: Scalars['ID']
  bookmarks?: Maybe<Array<User>>
  comments?: Maybe<Array<ForumComment>>
  category: Category
  content: Scalars['String']
  createdAt: DateItem
  emailTitle: Scalars['String']
  helped?: Maybe<Array<User>>
  followers?: Maybe<Array<User>>
  pollOptions?: Maybe<Array<ForumPollOption>>
  pollDuration: Scalars['Int']
  photos?: Maybe<Array<Scalars['String']>>
  reacts?: Maybe<Array<ForumReact>>
  report?: Maybe<Array<ForumReport>>
  slug: Scalars['String']
  topics?: Maybe<Array<ForumTag>>
  tagged?: Maybe<Array<Scalars['String']>>
  title: Scalars['String']
  type?: Maybe<ForumType>
  user: User
  updatedAt?: Maybe<Scalars['String']>
  youtubeLinks?: Maybe<Array<Scalars['String']>>
}

type ForumReact = {
  __typename?: 'ForumReact'
  type: ForumReactType
  user: User
}

enum ForumReactType {
  Like = 'LIKE',
  Laugh = 'LAUGH',
  Wow = 'WOW'
}

type ForumReport = {
  __typename?: 'ForumReport'
  reason: ForumReportReason
  user: User
}

export enum ForumReportReason {
  Abusive = 'ABUSIVE',
  Inappropriate = 'INAPPROPRIATE',
  Spam = 'SPAM'
}

type ForumResult = {
  __typename?: 'ForumResult'
  data?: Maybe<Array<ForumPost>>
  pages?: Maybe<Array<Scalars['Int']>>
  currentPage: Scalars['Int']
  totalPages: Scalars['Int']
  newPosts: Scalars['Int']
  helpful: Scalars['Int']
  yourPost: Scalars['Int']
  youHelped: Scalars['Int']
  resources: Scalars['Int']
}

export type ForumTag = {
  __typename?: 'ForumTag'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
}

export enum ForumType {
  Post = 'POST',
  Poll = 'POLL'
}

enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

type GenerateCertificateCompanyInput = {
  logo: Scalars['String']
  name: Scalars['String']
}

type GetChallengeInput = {
  company: Scalars['ID']
  challenge: Scalars['ID']
  level: SkillRank
  projectId?: Maybe<Scalars['ID']>
  skill: Scalars['ID']
  team?: Maybe<Array<GetChallengeTeamInput>>
  autoStart?: Maybe<Scalars['Boolean']>
}

type GetChallengeTeamInput = {
  user?: Maybe<Scalars['ID']>
  email: Scalars['String']
  status: UserChallengeTeamStatus
}

type GetForumInput = {
  page?: Maybe<Scalars['Int']>
  category: Scalars['ID']
  filters?: Maybe<Array<ForumFilter>>
  search?: Maybe<Scalars['String']>
  post?: Maybe<Scalars['ID']>
}

type GoogleLoginInput = {
  token: Scalars['String']
  isCustom?: Maybe<Scalars['Boolean']>
  referrer?: Maybe<Scalars['String']>
  role?: Maybe<UserRole>
  signupCode?: Maybe<Scalars['String']>
}

type GrantXpInput = {
  challenge: Scalars['ID']
  xp: Scalars['Int']
}

type Invitation = {
  __typename?: 'Invitation'
  id: Scalars['ID']
  email: Scalars['String']
  invitedBy: User
  name: Scalars['String']
  createdAt: Scalars['String']
}

type InvitationResult = {
  __typename?: 'InvitationResult'
  added?: Maybe<Scalars['Boolean']>
  invited?: Maybe<Scalars['Boolean']>
}

export type IsProjectAppliedResult = {
  __typename?: 'IsProjectAppliedResult'
  applied: Scalars['Boolean']
  typeform: Scalars['Boolean']
}

export type Job = {
  __typename?: 'Job'
  id: Scalars['ID']
  applicants?: Maybe<Array<JobApplicants>>
  benefits?: Maybe<Array<JobBenefits>>
  company: Company
  compensation: JobCompensation
  description: Scalars['String']
  duration?: Maybe<JobWorkDates>
  fakeApplicants: Scalars['Int']
  hours: JobHours
  isStanding: Scalars['Boolean']
  languages?: Maybe<Array<JobLanguage>>
  location?: Maybe<Scalars['String']>
  remote: Scalars['Boolean']
  responsibilities: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  skills: Array<JobSkills>
  students?: Maybe<Array<StudentType>>
  title: Scalars['String']
  type: JobType
  updates?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
}

export type JobApplicants = {
  __typename?: 'JobApplicants'
  cover: Scalars['String']
  createdAt: Scalars['String']
  status: JobApplicantStatus
  user: User
}

enum JobApplicantStatus {
  Pending = 'PENDING',
  Review = 'REVIEW',
  Rejected = 'REJECTED'
}

export type JobBenefits = {
  __typename?: 'JobBenefits'
  id: Scalars['ID']
  logo: Scalars['String']
  name: Scalars['String']
}

export enum JobCompensation {
  Coop = 'COOP',
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

type JobDurationInput = {
  duration?: Maybe<Scalars['Int']>
  durationType?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  flexible: Scalars['Boolean']
  start?: Maybe<Scalars['String']>
}

export enum JobHours {
  Full = 'FULL',
  Part = 'PART'
}

export type JobInput = {
  id?: Maybe<Scalars['ID']>
  benefits?: Maybe<Array<Scalars['ID']>>
  company: Scalars['ID']
  compensation: JobCompensation
  description: Scalars['String']
  duration?: Maybe<JobDurationInput>
  hours: JobHours
  isStanding: Scalars['Boolean']
  languages?: Maybe<Array<JobLanguageInput>>
  location?: Maybe<Scalars['String']>
  responsibilities: Scalars['String']
  requirements?: Maybe<Scalars['String']>
  students?: Maybe<Array<StudentType>>
  skills: Array<JobSkillInput>
  title: Scalars['String']
  type: JobType
  updates?: Maybe<Scalars['String']>
}

type JobLanguage = {
  __typename?: 'JobLanguage'
  language: Scalars['String']
  proficiency: LanguageProficiency
}

export type JobLanguageInput = {
  language: Scalars['String']
  proficiency: LanguageProficiency
}

export type JobSkillInput = {
  skill: Scalars['ID']
  level: SkillRank
}

export type JobSkills = {
  __typename?: 'JobSkills'
  level: SkillRank
  skill: Skill
}

export enum JobType {
  Externship = 'EXTERNSHIP',
  Internship = 'INTERNSHIP',
  Job = 'JOB'
}

type JobWorkDates = {
  __typename?: 'JobWorkDates'
  duration?: Maybe<Scalars['Int']>
  durationType?: Maybe<Scalars['String']>
  end?: Maybe<Scalars['String']>
  flexible: Scalars['Boolean']
  start?: Maybe<Scalars['String']>
}

export enum JoinCommunityType {
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY'
}

export enum LanguageProficiency {
  Elementary = 'ELEMENTARY',
  Limited = 'LIMITED',
  Professional = 'PROFESSIONAL',
  Native = 'NATIVE'
}

type LoginInput = {
  email: Scalars['String']
  password: Scalars['String']
}

export type MentorResult = {
  __typename?: 'MentorResult'
  id: Scalars['ID']
  firstName: Scalars['String']
  lastName: Scalars['String']
  photo: Scalars['String']
  categories: Array<Category>
  work: WorkExperience
}

type Message = {
  __typename?: 'Message'
  id: Scalars['ID']
  message: Scalars['String']
  type: Scalars['String']
  files?: Maybe<Array<ProjectFile>>
  createdBy: User
  createdAt: DateItem
}

export enum MessageReference {
  Project = 'PROJECT'
}

export enum MessageType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Link = 'LINK',
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

export type Meta = {
  __typename?: 'Meta'
  categories: Array<Category>
  challengesCategories?: Maybe<Array<MetaChallengesCategoryResult>>
  degrees: Array<Degree>
  fields: Array<Field>
  nationalities: Array<Scalars['String']>
  schools: Array<School>
  skills: Array<Skill>
  resources: Array<Resource>
  resourceTypes: Array<ResourceType>
}

type MetaChallengesCategoryResult = {
  __typename?: 'MetaChallengesCategoryResult'
  category?: Maybe<Category>
  challenges?: Maybe<Array<Challenge>>
}

export type MutationAutoVerifyArgs = {
  id: Scalars['ID']
  token: Scalars['String']
}

export type MutationAutoLoginArgs = {
  id: Scalars['ID']
}

export type MutationApplyForProjectArgs = {
  input: ApplyProjectInput
}

export type MutationCheckIfApprenticeCanApplyArgs = {
  categoryId: Scalars['String']
}

export type MutationAcceptProjectArgs = {
  project: Scalars['ID']
}

export type MutationBackdoorArgs = {
  id: Scalars['ID']
}

export type MutationCreateChatsArgs = {
  input: ChatGeneratorInput
}

export type MutationCreateSkillArgs = {
  input: SkillInput
}

export type MutationCreatePersonalityTagArgs = {
  input: PersonalityTagInput
}

export type MutationCreatePersonalityQuestionArgs = {
  input: PersonalityQuestionInput
}

export type MutationCreatePersonalityTraitArgs = {
  input: PersonalityTraitInput
}

export type MutationClearTaskNotificationArgs = {
  input: ClearTaskNotificationInput
}

export type MutationCloseProjectArgs = {
  project: Scalars['ID']
}

export type MutationDeleteProjectArgs = {
  projectId: Scalars['ID']
}

export type MutationDeletePersonalityTagArgs = {
  id: Scalars['ID']
}

export type MutationDeletePersonalityQuestionArgs = {
  id: Scalars['ID']
}

export type MutationDeletePersonalityTraitArgs = {
  id: Scalars['ID']
}

export type MutationDeletePaymentMethodArgs = {
  id: Scalars['ID']
}

export type MutationDeleteProgressArgs = {
  input: DeleteProgressInput
}

export type MutationDeleteTaskArgs = {
  id: Scalars['ID']
  project: Scalars['ID']
}

export type MutationForumActionArgs = {
  input: ForumActionInput
}

export type MutationFollowUserArgs = {
  id: Scalars['ID']
}

export type MutationJoinCommunityArgs = {
  category: Scalars['ID']
  type: JoinCommunityType
  leave?: Maybe<Scalars['Boolean']>
}

export type MutationPayTaskArgs = {
  input: PayTaskInput
}

export type MutationRegisterArgs = {
  user: RegisterInput
}

export type MutationReadNotificationArgs = {
  id: Scalars['ID']
}

export type MutationReadChatArgs = {
  thread: Scalars['ID']
}

export type MutationRemoveFromTalentHubArgs = {
  input: RemoveFromTalentHubInput
}

export type MutationSendChatArgs = {
  input: SendChatInput
}

export type MutationStoreCashoutArgs = {
  input: CashoutInput
}

export type MutationStoreCategoryArgs = {
  input: StoreCategoryInput
}

export type MutationStoreForumTagArgs = {
  name: Scalars['String']
  id?: Maybe<Scalars['ID']>
}

export type MutationStoreForumPostArgs = {
  input: StoreForumPostInput
}

export type MutationStoreForumCommentArgs = {
  input: StoreForumCommentInput
}

export type MutationStoreJobArgs = {
  input: JobInput
}

export type MutationStoreRewardArgs = {
  input: RewardInput
}

export type MutationStoreTermsArgs = {
  input: StoreTermsInput
}

export type MutationStorePipelineMessageArgs = {
  input: StorePipelineMessageInput
}

export type MutationStoreProjectFeedbackArgs = {
  input: ProjectStudentFeedbackInput
}

export type MutationStoreProjectTaskFileArgs = {
  input: StoreTaskFileInput
}

export type MutationStoreProjectTaskProgressArgs = {
  input: StoreProjectTaskProgressInput
}

export type MutationStoreProjectTaskArgs = {
  input: StoreTaskInput
}

export type MutationSubmitPersonalityTestArgs = {
  input: Array<PersonalityTestInput>
}

export type MutationStoreProgressReplyArgs = {
  input: StoreProgressReplyInput
}

export type MutationStorePaymentMethodArgs = {
  input: StorePaymentMethodInput
}

export type MutationSubmitTaskArgs = {
  input: SubmitTaskInput
}

export type MutationStoreQuickProjectArgs = {
  input: StoreQuickProjectInput
}

export type MutationStoreLastChatArgs = {
  message: Scalars['String']
  session: Scalars['ID']
}

export type MutationStartSubscriptionArgs = {
  input: StartSubscriptionInput
}

export type MutationSubmitCohortApplicationArgs = {
  input: SubmitCohortApplicationInput
}

export type MutationSubmitWebinarApplicationArgs = {
  input: SubmitWebinarApplicationInput
}

export type MutationStoreCohortProjectArgs = {
  input: StoreQuickProjectInput
}

export type MutationStoreCohortRecordingArgs = {
  id: Scalars['ID']
  recording: Scalars['String']
}

export type MutationTaskDescriptionQuestionArgs = {
  taskId: Scalars['ID']
  question: Scalars['String']
  files?: Maybe<Array<Scalars['ID']>>
}

export type MutationUpdateCertificateArgs = {
  input: UpdateCertificateInput
}

export type MutationUpdateProfileArgs = {
  user: UpdateProfileInput
}

export type MutationUpdateProjectApplicantStatusArgs = {
  input: UpdateProjectApplicantInput
}

export type MutationUpdateTaskStatusArgs = {
  input: UpdateTaskStatusInput
}

export type MutationUpdateCoverLetterArgs = {
  projectId: Scalars['ID']
  cover: Scalars['String']
}

export type MutationUnsubscribeArgs = {
  id: Scalars['ID']
  type: UnsubscribeType
}

export type MutationVerifyEmailArgs = {
  email: Scalars['String']
  code: Scalars['String']
}

export type MutationVerifyStudentsArgs = {
  user: Array<Scalars['ID']>
  categories: Array<Scalars['ID']>
}

export type MutationViewResourceArgs = {
  id: Scalars['ID']
}

export type MutationVotePollArgs = {
  input: VotePollInput
}

type Nda = {
  __typename?: 'NDA'
  content: Scalars['String']
  type: NdaType
}

export enum NdaType {
  Default = 'DEFAULT'
}

export type Notification = {
  __typename?: 'Notification'
  id: Scalars['ID']
  link: Scalars['String']
  message: Scalars['String']
  seen: Scalars['Boolean']
  title: Scalars['String']
  type: NotificationType
  user: User
  createdAt: Scalars['String']
}

export enum NotificationType {
  Challenge = 'CHALLENGE',
  Job = 'JOB',
  Project = 'PROJECT',
  Reward = 'REWARD',
  Session = 'SESSION',
  Community = 'COMMUNITY'
}

export enum OpportunityType {
  Externships = 'EXTERNSHIPS',
  Internships = 'INTERNSHIPS',
  Jobs = 'JOBS',
  Projects = 'PROJECTS',
  Volunteering = 'VOLUNTEERING'
}

export type PaginationInput = {
  limit: Scalars['Int']
  skip?: Maybe<Scalars['Int']>
}

export type PaymentMethod = {
  __typename?: 'PaymentMethod'
  id: Scalars['ID']
  expiry: Scalars['String']
  isPrimary: Scalars['Boolean']
  number: Scalars['String']
  name: Scalars['String']
  reference?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  isUsed: Scalars['Boolean']
  isPermanent: Scalars['Boolean']
}

enum PaymentMethodType {
  Mastercard = 'MASTERCARD',
  Visa = 'VISA'
}

export type PayProjectInput = {
  projectId: Scalars['ID']
  card: Scalars['ID']
  cvc?: Maybe<Scalars['String']>
  ip: Scalars['String']
}

export type PaySubscriptionInput = {
  company: Scalars['ID']
  cvc?: Maybe<Scalars['String']>
  ip: Scalars['String']
  project?: Maybe<Scalars['ID']>
}

export type PayTaskInput = {
  tasks: Array<Scalars['String']>
  card: Scalars['ID']
  cvc?: Maybe<Scalars['String']>
  ip: Scalars['String']
}

type PendingReviewResult = {
  __typename?: 'PendingReviewResult'
  id: Scalars['ID']
  total: Scalars['Int']
  items: Array<PendingReviewResultItems>
}

type PendingReviewResultItems = {
  __typename?: 'PendingReviewResultItems'
  level: SkillRank
  index: Scalars['Int']
  id: Scalars['ID']
  status: UserChallengeStatus
}

export type PendingSessionResult = {
  __typename?: 'PendingSessionResult'
  active: Array<Session>
  pending: Array<Session>
}

export type PersonalityQuestion = {
  __typename?: 'PersonalityQuestion'
  id: Scalars['ID']
  question: Scalars['String']
  stronglyAgree?: Maybe<Array<PersonalityTag>>
  agree?: Maybe<Array<PersonalityTag>>
  neutral?: Maybe<Array<PersonalityTag>>
  disagree?: Maybe<Array<PersonalityTag>>
  stronglyDisagree?: Maybe<Array<PersonalityTag>>
}

export type PersonalityQuestionInput = {
  id?: Maybe<Scalars['ID']>
  question: Scalars['String']
  stronglyAgree?: Maybe<Array<Scalars['ID']>>
  agree?: Maybe<Array<Scalars['ID']>>
  neutral?: Maybe<Array<Scalars['ID']>>
  disagree?: Maybe<Array<Scalars['ID']>>
  stronglyDisagree?: Maybe<Array<Scalars['ID']>>
}

export type PersonalityTag = {
  __typename?: 'PersonalityTag'
  id: Scalars['ID']
  max?: Maybe<Scalars['Int']>
  name: Scalars['String']
}

export type PersonalityTagInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
}

export type PersonalityTestInput = {
  tag: Scalars['String']
  points: Scalars['Int']
}

export type PersonalityTrait = {
  __typename?: 'PersonalityTrait'
  id: Scalars['ID']
  fallback: Scalars['Boolean']
  name: Scalars['String']
  description: Scalars['String']
  criteria: Array<Maybe<PersonalityTraitCriteria>>
}

type PersonalityTraitCriteria = {
  __typename?: 'PersonalityTraitCriteria'
  tag: PersonalityTag
  points: Scalars['Int']
}

type PersonalityTraitCriteriaInput = {
  tag: Scalars['ID']
  points: Scalars['Int']
}

export type PersonalityTraitInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  description: Scalars['String']
  criteria: Array<Maybe<PersonalityTraitCriteriaInput>>
}

export type PreferenceResult = {
  __typename?: 'PreferenceResult'
  rules?: Maybe<Array<ProjectApplicationPreference>>
  cohortId?: Maybe<Scalars['ID']>
}

export type Project = {
  __typename?: 'Project'
  id: Scalars['ID']
  activity?: Maybe<Array<ProjectActivity>>
  budget: Scalars['Int']
  commitment: ProjectCommitmentBenefits
  company: Company
  challenge?: Maybe<ProjectChallenge>
  createdBy: User
  cohort?: Maybe<Cohort>
  cohortSessions?: Maybe<Array<Session>>
  chat?: Maybe<Array<ProjectChat>>
  emails?: Maybe<Array<Scalars['String']>>
  fakeApplicants: Scalars['Int']
  feedback?: Maybe<ProjectFeedback>
  hired?: Maybe<Array<User>>
  isPaid: Scalars['Boolean']
  isApplicationOpen: Scalars['Boolean']
  isTemplate: Scalars['Boolean']
  meta: ProjectMeta
  mentorId?: Maybe<Scalars['String']>
  participants?: Maybe<Array<ProjectParticipant>>
  participantsOrder?: Maybe<Array<Scalars['String']>>
  preview?: Maybe<Scalars['String']>
  reference: Scalars['String']
  referenceable: Scalars['Boolean']
  reminders?: Maybe<Array<Scalars['String']>>
  session?: Maybe<Session>
  sessions?: Maybe<Array<Session>>
  status: ProjectStatus
  suggestedBudget: Scalars['Int']
  tasks?: Maybe<Array<ProjectTask>>
  talent: ProjectTalentMeta
  template?: Maybe<ProjectTemplate>
  type: ProjectType
  xp?: Maybe<Scalars['Int']>
  released?: Maybe<Array<ProjectReleasedXp>>
  revisions?: Maybe<Array<ProjectRevision>>
  seen?: Maybe<DateItem>
  voucher?: Maybe<Voucher>
  createdAt: DateItem
  startedAt?: Maybe<DateItem>
  endedAt?: Maybe<DateItem>
  updatedAt: DateItem
}

type ProjectActivity = {
  __typename?: 'ProjectActivity'
  date: Scalars['String']
  name: Scalars['String']
  description: Scalars['String']
  link: Scalars['String']
}

export enum ProjectApplicationPreference {
  ProfileNationality = 'PROFILE_NATIONALITY',
  ProfilePhone = 'PROFILE_PHONE',
  ProfileUniversity = 'PROFILE_UNIVERSITY',
  Cohort = 'COHORT'
}

type ProjectChallenge = {
  __typename?: 'ProjectChallenge'
  challenge: Challenge
  rank: SkillRank
}

export type ProjectChat = {
  __typename?: 'ProjectChat'
  id: Scalars['ID']
  author: User
  message: Scalars['String']
  type: ProjectChatType
  createdAt: Scalars['String']
  read: Scalars['Boolean']
  readers?: Maybe<Array<Scalars['String']>>
  reference: Scalars['String']
}

export enum ProjectChatType {
  Text = 'TEXT',
  Image = 'IMAGE',
  Link = 'LINK',
  Audio = 'AUDIO',
  Video = 'VIDEO'
}

type ProjectCommitmentBenefits = {
  __typename?: 'ProjectCommitmentBenefits'
  compensation: Scalars['Int']
  currency: Scalars['String']
  duration: Scalars['Int']
  durationType: Scalars['String']
  hours: Scalars['Int']
  type: Scalars['String']
  startDateType: ProjectStartDateType
  startDate?: Maybe<Scalars['String']>
}

type ProjectCommitmentInput = {
  compensation: Scalars['Int']
  currency: Scalars['String']
  duration: Scalars['Int']
  durationType: Scalars['String']
  hours: Scalars['Int']
  type: Scalars['String']
  startDateType: ProjectStartDateType
  startDate?: Maybe<Scalars['String']>
}

type ProjectFeedback = {
  __typename?: 'ProjectFeedback'
  company?: Maybe<ProjectFeedbackDetails>
  participant?: Maybe<Scalars['String']>
}

type ProjectFeedbackDetails = {
  __typename?: 'ProjectFeedbackDetails'
  text: Scalars['String']
  compliments?: Maybe<Array<Compliment>>
}

export type ProjectFile = {
  __typename?: 'ProjectFile'
  id: Scalars['ID']
  isLink: Scalars['Boolean']
  isInitial: Scalars['Boolean']
  name: Scalars['String']
  url: Scalars['String']
  uploader: User
  createdAt: DateItem
}

export type ProjectFileResult = {
  __typename?: 'ProjectFileResult'
  task: ProjectTask
  files?: Maybe<Array<ProjectFile>>
}

type ProjectMeta = {
  __typename?: 'ProjectMeta'
  category: Category
  description: Scalars['String']
  expectations: Scalars['String']
  title: Scalars['String']
  isRemote: Scalars['Boolean']
  isCohort: Scalars['Boolean']
  location?: Maybe<Scalars['String']>
}

type ProjectMetaInput = {
  category: Scalars['ID']
  description: Scalars['String']
  expectations: Scalars['String']
  title: Scalars['String']
  isRemote: Scalars['Boolean']
  location?: Maybe<Scalars['String']>
}

export type ProjectParticipant = {
  __typename?: 'ProjectParticipant'
  appliedAt?: Maybe<Scalars['String']>
  challenge?: Maybe<UserChallenge>
  cover: Scalars['String']
  isNotifiedForChallenge: Scalars['Boolean']
  messages?: Maybe<Array<Message>>
  shortlisted: Scalars['Boolean']
  status: ProjectParticipantStatus
  user: User
}

export enum ProjectParticipantStatus {
  Contacted = 'CONTACTED',
  Declined = 'DECLINED',
  Hired = 'HIRED',
  Offered = 'OFFERED',
  Passed = 'PASSED',
  Pending = 'PENDING',
  Shortlisted = 'SHORTLISTED'
}

type ProjectProgress = {
  __typename?: 'ProjectProgress'
  id: Scalars['ID']
  createdBy: User
  edited: Scalars['Boolean']
  files?: Maybe<Array<ProjectFile>>
  message: Scalars['String']
  response?: Maybe<Array<ProjectProgressResponse>>
  statusFrom?: Maybe<ProjectTaskStatus>
  statusTo?: Maybe<ProjectTaskStatus>
  createdAt: DateItem
}

type ProjectProgressResponse = {
  __typename?: 'ProjectProgressResponse'
  message: Scalars['String']
  type: Scalars['String']
  files?: Maybe<Array<ProjectFile>>
  createdBy: User
  createdAt: DateItem
}

export type ProjectQueryInput = {
  userId?: Maybe<Scalars['String']>
  viewMode?: Maybe<Scalars['Boolean']>
}

type ProjectReleasedXp = {
  __typename?: 'ProjectReleasedXp'
  date: DateItem
  xp: Scalars['Int']
}

export type ProjectResult = {
  __typename?: 'ProjectResult'
  id: Scalars['ID']
  title: Scalars['String']
  company: ProjectResultCompany
  startDate?: Maybe<DateItem>
  categoryName: Scalars['String']
  duration: Scalars['Int']
  durationType: Scalars['String']
  status: ProjectStatus
}

type ProjectResultCompany = {
  __typename?: 'ProjectResultCompany'
  id: Scalars['ID']
  name: Scalars['String']
  logo: Scalars['String']
}

type ProjectRevision = {
  __typename?: 'ProjectRevision'
  notes: Scalars['String']
  createdAt: DateItem
}

export enum ProjectSkillType {
  Preferred = 'PREFERRED',
  Required = 'REQUIRED'
}

export enum ProjectStartDateType {
  Asap = 'ASAP',
  Specific = 'SPECIFIC'
}

export enum ProjectStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Closing = 'CLOSING',
  Draft = 'DRAFT',
  Ended = 'ENDED',
  Live = 'LIVE',
  Paid = 'PAID',
  Pending = 'PENDING',
  Unpublished = 'UNPUBLISHED',
  Deleted = 'DELETED'
}

export type ProjectStudentFeedbackInput = {
  projectId: Scalars['String']
  text: Scalars['String']
  referenceable: Scalars['Boolean']
}

type ProjectTalentMeta = {
  __typename?: 'ProjectTalentMeta'
  field?: Maybe<Array<Field>>
  location?: Maybe<Array<Scalars['String']>>
  skills: Array<ProjectTalentSkill>
  tools?: Maybe<Array<Category>>
  university?: Maybe<Array<School>>
  gradYearFrom: Scalars['String']
  gradYearTo: Scalars['String']
  nationality?: Maybe<Array<Scalars['String']>>
  language?: Maybe<Array<Scalars['String']>>
  gender: Scalars['String']
}

type ProjectTalentMetaInput = {
  challenge?: Maybe<StoreProjectChallengeInput>
  field?: Maybe<Array<Scalars['ID']>>
  location?: Maybe<Array<Scalars['String']>>
  skills?: Maybe<Array<ProjectTalentSkillInput>>
  school?: Maybe<Array<Scalars['ID']>>
  tools?: Maybe<Array<Scalars['ID']>>
  gradYearFrom: Scalars['String']
  gradYearTo: Scalars['String']
  nationality?: Maybe<Array<Scalars['String']>>
  language?: Maybe<Array<Scalars['String']>>
  gender: Scalars['String']
}

export type ProjectTalentSkill = {
  __typename?: 'ProjectTalentSkill'
  skill: Skill
  type: ProjectSkillType
  isPrimary: Scalars['Boolean']
}

type ProjectTalentSkillInput = {
  skill: Scalars['ID']
  type: ProjectSkillType
  isPrimary: Scalars['Boolean']
}

export type ProjectTask = {
  __typename?: 'ProjectTask'
  id: Scalars['ID']
  activity?: Maybe<Array<ProjectTaskActivity>>
  suggestedBudget: Scalars['Int']
  budget: Scalars['Int']
  name: Scalars['String']
  description: Scalars['String']
  deadline: DateItem
  notification: ProjectTaskUnreadNotification
  participants?: Maybe<Array<User>>
  progress?: Maybe<Array<ProjectProgress>>
  qa?: Maybe<Array<ProjectTaskQa>>
  rating?: Maybe<Scalars['Int']>
  reference: Scalars['String']
  resources?: Maybe<Array<ProjectFile>>
  status: ProjectTaskStatus
  submissions?: Maybe<Array<ProjectFile>>
  submissionData?: Maybe<Array<ProjectTaskSubmission>>
  submittedBy: User
  startedAt?: Maybe<DateItem>
  submittedAt?: Maybe<DateItem>
  completedAt?: Maybe<DateItem>
  isOverdue?: Maybe<Scalars['Boolean']>
  effort?: Maybe<Scalars['Int']>
  turnover?: Maybe<Scalars['Int']>
  xp?: Maybe<Scalars['Int']>
}

type ProjectTaskActivity = {
  __typename?: 'ProjectTaskActivity'
  id: Scalars['ID']
  author: User
  type: ProjectTaskActivityType
  createdAt?: Maybe<DateItem>
  status?: Maybe<ProjectTaskStatus>
  description: Scalars['String']
  details?: Maybe<Scalars['String']>
  files?: Maybe<Array<ProjectFile>>
}

export enum ProjectTaskActivityType {
  Status = 'STATUS',
  Details = 'DETAILS',
  Upload = 'UPLOAD',
  Duration = 'DURATION'
}

export type ProjectTaskCart = {
  __typename?: 'ProjectTaskCart'
  id: Scalars['ID']
  amountDue: Scalars['Int']
  name: Scalars['String']
  description: Scalars['String']
  deadline: DateItem
  resources?: Maybe<Array<ProjectFile>>
  effort?: Maybe<Scalars['Int']>
  xp?: Maybe<Scalars['Int']>
  isPaid: Scalars['Boolean']
  projectId: Scalars['String']
}

type ProjectTaskGroupInput = {
  name: Scalars['String']
  tasks?: Maybe<Array<Scalars['ID']>>
}

type ProjectTaskQa = {
  __typename?: 'ProjectTaskQA'
  id: Scalars['ID']
  askedBy: User
  question: Scalars['String']
  answer?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  answeredAt?: Maybe<DateItem>
  createdAt: DateItem
}

export enum ProjectTaskStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Revise = 'REVISE',
  Pending = 'PENDING',
  Inprogress = 'INPROGRESS',
  Review = 'REVIEW',
  Stuck = 'STUCK',
  Submitted = 'SUBMITTED'
}

type ProjectTaskSubmission = {
  __typename?: 'ProjectTaskSubmission'
  description?: Maybe<Scalars['String']>
  files?: Maybe<Array<ProjectFile>>
  createdAt: DateItem
}

type ProjectTaskUnreadNotification = {
  __typename?: 'ProjectTaskUnreadNotification'
  corporateQA: Array<Scalars['String']>
  corporateUpdate: Array<Scalars['String']>
  studentQA: Array<Scalars['String']>
  studentUpdate: Array<Scalars['String']>
}

type ProjectTemplate = {
  __typename?: 'ProjectTemplate'
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Category>>
}

export enum ProjectType {
  FlexibleDeliverables = 'FLEXIBLE_DELIVERABLES',
  InnovationIdeas = 'INNOVATION_IDEAS',
  SpecificDeliverables = 'SPECIFIC_DELIVERABLES',
  QuickProject = 'QUICK_PROJECT'
}

export type QueryAdminProjectsArgs = {
  page?: Maybe<Scalars['Int']>
}

export type QueryAdminCohortsArgs = {
  page?: Maybe<Scalars['Int']>
}

export type QueryCompanyArgs = {
  companyId: Scalars['ID']
}

export type QueryChallengeSessionsArgs = {
  input?: Maybe<ChallengeSessionInput>
}

export type QueryDepthAnalyticsArgs = {
  input?: Maybe<UserAnalyticsFilter>
}

export type QueryGetSubscribersArgs = {
  keyword: Scalars['String']
}

export type QueryGetProjectApplicationPreferencesArgs = {
  projectId: Scalars['ID']
}

export type QueryIsProjectAppliedArgs = {
  projectId: Scalars['ID']
}

export type QueryGetTypeformResponseArgs = {
  userId: Scalars['ID']
  projectId: Scalars['ID']
}

export type QueryNotificationsArgs = {
  input?: Maybe<PaginationInput>
}

export type QueryProjectArgs = {
  projectId: Scalars['ID']
}

export type QueryPublicProfileArgs = {
  uri: Scalars['String']
}

export type QueryThreadArgs = {
  id: Scalars['ID']
}

export type QueryUserChallengeArgs = {
  id: Scalars['ID']
}

type Question = {
  __typename?: 'Question'
  id: Scalars['ID']
  attachments?: Maybe<Array<Attachment>>
  body: Scalars['String']
  categories: Array<Category>
  expert?: Maybe<User>
  language: QuestionLanguage
  type: SessionType
  user: User
  createdAt: Scalars['String']
}

enum QuestionLanguage {
  Arabic = 'ARABIC',
  English = 'ENGLISH'
}

export type QuickTaskResourceInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  url: Scalars['String']
  isLink?: Maybe<Scalars['Boolean']>
}

export type RateSessionInput = {
  compliments?: Maybe<Array<Scalars['ID']>>
  feedback?: Maybe<Scalars['String']>
  flag: Scalars['Boolean']
  issue?: Maybe<Scalars['String']>
  rating: Scalars['Int']
  sessionId: Scalars['ID']
}

export type RegisterInput = {
  categories?: Maybe<Array<Scalars['ID']>>
  email: Scalars['String']
  firstName: Scalars['String']
  isProfessional?: Maybe<Scalars['Boolean']>
  isCustom?: Maybe<Scalars['Boolean']>
  lastName: Scalars['String']
  password: Scalars['String']
  referrer?: Maybe<Scalars['String']>
  role?: Maybe<UserRole>
  signupCode?: Maybe<Scalars['String']>
}

export type RemoveFromTalentHubInput = {
  userId: Scalars['ID']
  categoryId: Scalars['ID']
  reason: Scalars['String']
}

export type Resource = {
  __typename?: 'Resource'
  id: Scalars['ID']
  category?: Maybe<Array<Category>>
  description?: Maybe<Scalars['String']>
  file?: Maybe<ResourceFile>
  flagReason: Scalars['String']
  isFlagged: Scalars['Boolean']
  isQureos: Scalars['Boolean']
  link?: Maybe<Scalars['String']>
  name: Scalars['String']
  topic?: Maybe<ForumTag>
  type?: Maybe<ResourceType>
  views: Scalars['Int']
  uploader?: Maybe<User>
}

type ResourceFile = {
  __typename?: 'ResourceFile'
  uri: Scalars['String']
  type: Scalars['String']
}

type ResourceType = {
  __typename?: 'ResourceType'
  id: Scalars['ID']
  name: Scalars['String']
  slug: Scalars['String']
}

export type Reward = {
  __typename?: 'Reward'
  id: Scalars['ID']
  cost: Scalars['Int']
  details: Scalars['String']
  monetary: Scalars['Int']
  name: Scalars['String']
  photo: Scalars['String']
  redeemRequest?: Maybe<Array<RewardRedeem>>
}

type RewardInput = {
  id?: Maybe<Scalars['ID']>
  cost: Scalars['Int']
  details: Scalars['String']
  monetary: Scalars['Int']
  name: Scalars['String']
  photo: Scalars['String']
}

type RewardRedeem = {
  __typename?: 'RewardRedeem'
  user: User
  createdAt: Scalars['String']
}

export enum SalaryType {
  Experience = 'EXPERIENCE',
  ExpensesCovered = 'EXPENSES_COVERED',
  GraduateWage = 'GRADUATE_WAGE',
  NotSure = 'NOT_SURE'
}

export type School = {
  __typename?: 'School'
  id: Scalars['ID']
  domains: Array<Scalars['String']>
  name: Scalars['String']
  slug: Scalars['String']
  verified: Scalars['Boolean']
}

export type SchoolInput = {
  id?: Maybe<Scalars['ID']>
  domain?: Maybe<Array<Scalars['String']>>
  name: Scalars['String']
  verified: Scalars['Boolean']
}

export type SendChatInput = {
  message: Scalars['String']
  referenceId: Scalars['String']
  thread: Scalars['ID']
}

export type Session = {
  __typename?: 'Session'
  id: Scalars['ID']
  askee?: Maybe<User>
  chats?: Maybe<Array<SessionChat>>
  compliments?: Maybe<SessionCompliments>
  details: Scalars['String']
  files?: Maybe<Array<Scalars['String']>>
  issue?: Maybe<Scalars['String']>
  initialFiles?: Maybe<Array<Scalars['String']>>
  isUpgraded: Scalars['Boolean']
  isAdminNotified: Scalars['Boolean']
  last?: Maybe<SessionLastChat>
  mentor?: Maybe<User>
  origin: SessionOrigin
  reference: Scalars['String']
  room?: Maybe<Scalars['String']>
  skill?: Maybe<Skill>
  level: SkillRank
  status: SessionStatus
  type: SessionType
  createdAt?: Maybe<Scalars['String']>
}

export type SessionChat = {
  __typename?: 'SessionChat'
  id: Scalars['ID']
  author: User
  message: Scalars['String']
  type: ProjectChatType
  createdAt: Scalars['String']
  read: Scalars['Boolean']
  readers?: Maybe<Array<Scalars['String']>>
  reference: Scalars['String']
}

type SessionCompliments = {
  __typename?: 'SessionCompliments'
  mentor: SessionRating
  user: SessionRating
}

type SessionLastChat = {
  __typename?: 'SessionLastChat'
  text: Scalars['String']
  date: Scalars['String']
}

export enum SessionOrigin {
  Challenges = 'CHALLENGES',
  Project = 'PROJECT',
  Question = 'QUESTION'
}

type SessionRating = {
  __typename?: 'SessionRating'
  badges?: Maybe<Array<Compliment>>
  feedback?: Maybe<Scalars['String']>
  rating?: Maybe<Scalars['Int']>
}

export enum SessionStatus {
  Active = 'ACTIVE',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Reported = 'REPORTED'
}

export enum SessionType {
  P2E = 'P2E',
  P2P = 'P2P'
}

export type Settings = {
  __typename?: 'Settings'
  id: Scalars['ID']
  conduct?: Maybe<Scalars['String']>
  nda?: Maybe<SettingsNda>
  privacy?: Maybe<Scalars['String']>
  project?: Maybe<Scalars['String']>
  terms?: Maybe<TermSettings>
}

type SettingsNda = {
  __typename?: 'SettingsNDA'
  default?: Maybe<Nda>
}

export type Skill = {
  __typename?: 'Skill'
  id: Scalars['ID']
  icon?: Maybe<Scalars['String']>
  name: Scalars['String']
  tagline?: Maybe<Scalars['String']>
  type: SkillType
  color: Scalars['String']
}

type SkillActiveChallenge = {
  __typename?: 'SkillActiveChallenge'
  beginner: Array<UserChallenge>
  proficient: Array<UserChallenge>
  skilled: Array<UserChallenge>
}

export type SkillChallenge = {
  __typename?: 'SkillChallenge'
  beginner: Array<Challenge>
  proficient: Array<Challenge>
  skilled: Array<Challenge>
}

export type SkillInput = {
  icon: Scalars['String']
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  tagline?: Maybe<Scalars['String']>
  type: Scalars['String']
  color: Scalars['String']
}

export enum SkillRank {
  Unverified = 'UNVERIFIED',
  Beginner = 'BEGINNER',
  Proficient = 'PROFICIENT',
  Skilled = 'SKILLED'
}

export enum SkillType {
  Primary = 'PRIMARY',
  Subskill = 'SUBSKILL'
}

export type StartSubscriptionInput = {
  plan: CompanySubscriptionPlan
  company: Scalars['ID']
}

type StatusesFilter = {
  __typename?: 'StatusesFilter'
  active: Scalars['Boolean']
  name: Scalars['String']
  value: Scalars['String']
}

export type StoreCategoryInput = {
  id?: Maybe<Scalars['ID']>
  color?: Maybe<Scalars['String']>
  name: Scalars['String']
  tagline?: Maybe<Scalars['String']>
  type: CategoryType
}

export type StoreCohortInput = {
  id?: Maybe<Scalars['ID']>
  category: Scalars['ID']
  company: Scalars['ID']
  competition?: Maybe<Scalars['Boolean']>
  competitionRsvp?: Maybe<CompetitionRsvp>
  proficiency: Scalars['ID']
  project: Scalars['ID']
  rewardXP: Scalars['Int']
  startDate: DateItemInput
  endDate: DateItemInput
  enrollBy: DateItemInput
  cohort: Array<StoreCohortModuleInput>
}

type StoreCohortMeetingInput = {
  startDate: DateItemInput
  endDate: DateItemInput
  link?: Maybe<Scalars['String']>
}

type StoreCohortModuleInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  program: Array<StoreCohortProgramInput>
  tasks: Array<Scalars['ID']>
}

type StoreCohortProgramInput = {
  description?: Maybe<Scalars['String']>
  startDate: DateItemInput
  title: Scalars['String']
  link?: Maybe<Scalars['String']>
  meeting?: Maybe<StoreCohortMeetingInput>
}

export type StoreCompanyInput = {
  id?: Maybe<Scalars['ID']>
  brief?: Maybe<Scalars['String']>
  domains?: Maybe<Array<Scalars['String']>>
  industry?: Maybe<Array<Scalars['ID']>>
  location?: Maybe<Scalars['String']>
  logo: Scalars['String']
  meta?: Maybe<StoreCompanyMetaInput>
  name: Scalars['String']
}

type StoreCompanyMetaInput = {
  employees?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
  location?: Maybe<Array<Scalars['String']>>
  facebook?: Maybe<Scalars['String']>
  twitter?: Maybe<Scalars['String']>
  instagram?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  media?: Maybe<Array<StoreCompanyMetaMediaInput>>
}

type StoreCompanyMetaMediaInput = {
  url: Scalars['String']
  isPrimary: Scalars['Boolean']
  isVideo?: Maybe<Scalars['Boolean']>
}

export type StoreForumCommentInput = {
  id?: Maybe<Scalars['ID']>
  post: Scalars['ID']
  comment: Scalars['String']
  recipient?: Maybe<Scalars['ID']>
}

export type StoreForumPostInput = {
  id?: Maybe<Scalars['ID']>
  category: Scalars['ID']
  content: Scalars['String']
  emailTitle?: Maybe<Scalars['String']>
  pollOptions?: Maybe<Array<Scalars['String']>>
  pollDuration?: Maybe<Scalars['Int']>
  title: Scalars['String']
  topics?: Maybe<Array<Scalars['ID']>>
  type?: Maybe<ForumType>
  photos?: Maybe<Array<Scalars['String']>>
  youtubeLinks?: Maybe<Array<Scalars['String']>>
}

type StoreNdaInput = {
  content: Scalars['String']
  type: NdaType
}

export type StorePaymentMethodInput = {
  id?: Maybe<Scalars['ID']>
  expiry: Scalars['String']
  name: Scalars['String']
  number: Scalars['String']
  reference?: Maybe<Scalars['String']>
  token?: Maybe<Scalars['String']>
  isPermanent: Scalars['Boolean']
}

export type StorePipelineMessageInput = {
  applicant: Scalars['ID']
  files?: Maybe<Array<Scalars['ID']>>
  message?: Maybe<Scalars['String']>
  projectId: Scalars['ID']
}

export type StoreProgressReplyInput = {
  description: Scalars['String']
  files?: Maybe<Array<Scalars['ID']>>
  progressId: Scalars['ID']
  projectId: Scalars['ID']
}

type StoreProjectChallengeInput = {
  challenge: Scalars['ID']
  rank: SkillRank
}

export type StoreProjectInput = {
  id?: Maybe<Scalars['ID']>
  commitment: ProjectCommitmentInput
  isPreview?: Maybe<Scalars['Boolean']>
  isTemplate?: Maybe<Scalars['Boolean']>
  meta: ProjectMetaInput
  tasks?: Maybe<Array<ProjectTaskGroupInput>>
  talent: ProjectTalentMetaInput
  template?: Maybe<StoreProjectTemplateInput>
  type: ProjectType
  createdAt: DateItemInput
}

export type StoreProjectTaskProgressInput = {
  id?: Maybe<Scalars['ID']>
  description: Scalars['String']
  files?: Maybe<Array<Scalars['ID']>>
  projectId: Scalars['ID']
  taskId: Scalars['ID']
}

type StoreProjectTemplateInput = {
  categories?: Maybe<Array<Scalars['ID']>>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
}

export type StoreQuickProjectInput = {
  id?: Maybe<Scalars['ID']>
  title: Scalars['String']
  category: Scalars['ID']
  description: Scalars['String']
  expectations: Scalars['String']
  isDraft?: Maybe<Scalars['Boolean']>
  type?: Maybe<ProjectType>
  company: Scalars['ID']
  sponsoredId?: Maybe<Scalars['ID']>
  tasks: Array<StoreQuickProjectTaskInput>
}

export type StoreQuickProjectTaskInput = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  description: Scalars['String']
  budget: Scalars['Int']
  suggestedBudget: Scalars['Int']
  deadline: Scalars['String']
  effort: Scalars['Int']
  resources?: Maybe<Array<QuickTaskResourceInput>>
}

export type StoreQuickTaskInput = {
  name: Scalars['String']
  description: Scalars['String']
  category: Scalars['ID']
  effort: Scalars['Int']
  turnover: Scalars['Int']
  resources?: Maybe<Array<QuickTaskResourceInput>>
  expectations: Scalars['String']
  company: Scalars['ID']
}

export type StoreTaskFileInput = {
  project: Scalars['ID']
  link: Scalars['String']
  isInitial?: Maybe<Scalars['Boolean']>
  name: Scalars['String']
  date?: Maybe<DateItemInput>
}

export type StoreTaskInput = {
  id?: Maybe<Scalars['ID']>
  project: Scalars['ID']
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  deadline?: Maybe<DateItemInput>
  resources?: Maybe<Array<Scalars['ID']>>
  status?: Maybe<ProjectTaskStatus>
  submissions?: Maybe<Array<Scalars['ID']>>
  budget: Scalars['Int']
  turnover?: Maybe<Scalars['Int']>
}

export type StoreTermsInput = {
  conduct?: Maybe<Scalars['String']>
  mentor?: Maybe<Scalars['String']>
  nda?: Maybe<StoreNdaInput>
  privacy?: Maybe<Scalars['String']>
  project?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
}

export enum StudentType {
  Freshman = 'FRESHMAN',
  Junior = 'JUNIOR',
  Sophomore = 'SOPHOMORE',
  Senior = 'SENIOR'
}

export type SubmitCohortApplicationInput = {
  answers?: Maybe<Array<CohortApplicationAnswerInput>>
  cohort: Scalars['ID']
}

export type SubmitTaskInput = {
  description?: Maybe<Scalars['String']>
  turnover?: Maybe<Scalars['Int']>
  files?: Maybe<Array<Scalars['ID']>>
  projectId: Scalars['ID']
  rating?: Maybe<Scalars['Int']>
  status?: Maybe<ProjectTaskStatus>
  taskId: Scalars['ID']
  updateId?: Maybe<Scalars['ID']>
  deadline?: Maybe<DateItemInput>
}

export type SubmitWebinarApplicationInput = {
  answers?: Maybe<Array<Scalars['String']>>
}

type TermSettings = {
  __typename?: 'TermSettings'
  mentor?: Maybe<Scalars['String']>
  website?: Maybe<Scalars['String']>
}

export type Transaction = {
  __typename?: 'Transaction'
  id: Scalars['ID']
  amount: Scalars['String']
  details: Scalars['String']
  projectReference?: Maybe<Scalars['String']>
  reference: Scalars['String']
  response: Scalars['String']
  status: TransactionStatus
  tasks: Array<Scalars['String']>
  type: TransactionType
  user: User
  value: Scalars['String']
  createdAt: Scalars['String']
}

export enum TransactionStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

enum TransactionType {
  Session = 'SESSION',
  Project = 'PROJECT',
  Subscription = 'SUBSCRIPTION',
  Task = 'TASK'
}

export type UnreadProps = {
  __typename?: 'UnreadProps'
  id: Scalars['String']
  value: Scalars['Int']
}

export enum UnsubscribeType {
  Project = 'PROJECT',
  Challenge = 'CHALLENGE'
}

export type UpdateCertificateInput = {
  certificateId: Scalars['ID']
  image: Scalars['String']
  isPublic: Scalars['Boolean']
}

type UpdatePasswordInput = {
  currentPassword: Scalars['String']
  newPassword: Scalars['String']
}

type UpdatePresenceInput = {
  behance?: Maybe<Scalars['String']>
  github?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
}

export type UpdateProfileInput = {
  bio?: Maybe<Scalars['String']>
  categories?: Maybe<Array<Scalars['ID']>>
  email?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  meta?: Maybe<UpdateUserMetaInput>
  notification?: Maybe<UpdateUserNotificationInput>
  personalityTestStarted?: Maybe<Scalars['Boolean']>
  photo?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phoneCountryCode?: Maybe<Scalars['String']>
  phonePrefix?: Maybe<Scalars['String']>
  presence?: Maybe<UpdatePresenceInput>
  password?: Maybe<UpdatePasswordInput>
  url?: Maybe<Scalars['String']>
  work?: Maybe<Array<UpdateWorkExperienceInput>>
  resumeUrl?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
}

export type UpdateProjectApplicantInput = {
  cover?: Maybe<Scalars['String']>
  order?: Maybe<Array<Scalars['String']>>
  projectId: Scalars['ID']
  status?: Maybe<ProjectParticipantStatus>
  userId: Scalars['ID']
}

export type UpdateProjectDetailsInput = {
  projectId: Scalars['ID']
  description?: Maybe<Scalars['String']>
  expectation?: Maybe<Scalars['String']>
}

export type UpdateTaskStatusInput = {
  projectId: Scalars['ID']
  status: ProjectTaskStatus
  taskId: Scalars['ID']
}

type UpdateUserMetaInput = {
  banks?: Maybe<Array<BankInput>>
  birthday?: Maybe<Scalars['String']>
  company?: Maybe<Scalars['String']>
  certifications?: Maybe<Array<UserCertificationInput>>
  gender?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  interests?: Maybe<Array<Scalars['ID']>>
  languages?: Maybe<Array<UserLanguageInput>>
  nationality?: Maybe<Scalars['String']>
  otherInterests?: Maybe<Array<Scalars['ID']>>
  preferences?: Maybe<UpdateUserMetaPreferencesInput>
  residence?: Maybe<Scalars['String']>
  skills?: Maybe<Array<Scalars['ID']>>
  schools?: Maybe<Array<UserSchoolInput>>
  showTour?: Maybe<Scalars['Boolean']>
  secondaryEmail?: Maybe<Scalars['String']>
  startDate?: Maybe<Scalars['String']>
  tools?: Maybe<Array<Scalars['ID']>>
  viewedTour?: Maybe<Array<Scalars['String']>>
  website?: Maybe<Scalars['String']>
}

type UpdateUserMetaPreferencesInput = {
  experience?: Maybe<Array<Scalars['ID']>>
  opportunity?: Maybe<Array<OpportunityType>>
  salary?: Maybe<Array<SalaryType>>
}

type UpdateUserNotificationInput = {
  projects?: Maybe<Scalars['Boolean']>
  challenges?: Maybe<Scalars['Boolean']>
}

type UpdateWorkExperienceInput = {
  company?: Maybe<Scalars['ID']>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  isCurrent: Scalars['Boolean']
  name: Scalars['String']
  startDate: Scalars['String']
  role: Scalars['String']
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  _id?: Scalars['ID']
  settings?: IApprenticesSettings
  bio?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  categories: Array<Category>
  certificates: Array<Certificate>
  companies?: Maybe<Array<UserCompany>>
  completedProjects?: Maybe<Array<CompletedProject>>
  credits: Scalars['Int']
  email: Scalars['String']
  emailVerified: Scalars['Boolean']
  firstName: Scalars['String']
  lastName: Scalars['String']
  loggedIn: Scalars['Int']
  googleId?: Maybe<Scalars['String']>
  isFake: Scalars['Boolean']
  isDemo: Scalars['Boolean']
  meta?: any //UserMeta
  mentees?: Maybe<Array<User>>
  notification?: Maybe<UserNotification>
  paymentMethods?: Maybe<Array<PaymentMethod>>
  personalityTraits?: Maybe<Array<PersonalityTrait>>
  personalityTestStarted: Scalars['Boolean']
  photo?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  phoneCountryCode?: Maybe<Scalars['String']>
  phonePrefix?: Maybe<Scalars['String']>
  phoneVerified: Scalars['Boolean']
  presence?: Maybe<UserPresence>
  referrals?: Maybe<Array<User>>
  referralCode?: Maybe<Scalars['String']>
  role: UserRole
  requestedVerification?: Maybe<Scalars['String']>
  status: UserStatus
  signupCode?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  work?: Maybe<Array<WorkExperience>>
  xp?: Maybe<Scalars['Int']>
  createdAt: Scalars['String']
  verified?: Maybe<Array<Category>>
  cohortApplied: Scalars['Boolean']
  cohortCompleted: Scalars['Boolean']
  projectAssigned: Scalars['Boolean']
  resumeUrl?: Maybe<Scalars['String']>
  timezone?: Maybe<Scalars['String']>
  nationality?: Maybe<Scalars['String']>
  showReward?: Boolean
  codeExpiryDateTime?: Date
  deleteAccountVerified?: Boolean
  availability: IProfileUserAvailabilityDto
  defaultCompany?: any
}

type UserAnalyticsDateRange = {
  from: Scalars['String']
  until?: Maybe<Scalars['String']>
}

export type UserAnalyticsFilter = {
  date?: Maybe<UserAnalyticsDateRange>
  nationality?: Maybe<Scalars['String']>
  school?: Maybe<Scalars['ID']>
  profileStrength?: Maybe<Scalars['Int']>
  hasPersonalityTrait?: Maybe<Scalars['Boolean']>
  pagination: UserAnalyticsPagination
  skills?: Maybe<Array<Scalars['ID']>>
}

type UserAnalyticsPagination = {
  limit: Scalars['Int']
  skip: Scalars['Int']
}

type UserAnalyticsResult = {
  __typename?: 'UserAnalyticsResult'
  total: Scalars['Int']
  users: Array<User>
  skills: Array<Skill>
}

type UserCertification = {
  __typename?: 'UserCertification'
  certificationName: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  companyUrl: Scalars['String']
  companyLogo?: Maybe<Scalars['String']>
  credentialUrl?: Maybe<Scalars['String']>
  issueDate?: Maybe<Scalars['String']>
}

type UserCertificationInput = {
  certificationName: Scalars['String']
  companyName?: Maybe<Scalars['String']>
  companyLogo?: Maybe<Scalars['String']>
  companyUrl: Scalars['String']
  credentialUrl?: Maybe<Scalars['String']>
  issueDate?: Maybe<Scalars['String']>
}

export type UserChallenge = {
  __typename?: 'UserChallenge'
  id: Scalars['ID']
  challenge: Challenge
  company: DummyCompany
  deadline?: Maybe<Scalars['String']>
  effort: Scalars['String']
  index: Scalars['Int']
  isScheduled?: Maybe<Scalars['Boolean']>
  lastNotified?: Maybe<Scalars['String']>
  level: SkillRank
  nextId?: Maybe<Scalars['String']>
  projectId?: Maybe<Scalars['String']>
  session?: Maybe<Session>
  rating?: Maybe<Scalars['Int']>
  review?: Maybe<Array<UserChallengeReview>>
  skill: Skill
  status: UserChallengeStatus
  startedAt?: Maybe<Scalars['String']>
  submittedAt?: Maybe<Scalars['String']>
  submission?: Maybe<UserChallengeSubmission>
  team?: Maybe<Array<UserChallengeTeam>>
  user: User
  xp?: Maybe<Scalars['Int']>
}

type UserChallengeEvaluation = {
  __typename?: 'UserChallengeEvaluation'
  index?: Maybe<Scalars['Int']>
  score?: Maybe<Scalars['Int']>
  feedback?: Maybe<Scalars['String']>
}

type UserChallengeReview = {
  __typename?: 'UserChallengeReview'
  evaluation?: Maybe<Array<Maybe<UserChallengeEvaluation>>>
  notes?: Maybe<Scalars['String']>
  reviewer?: Maybe<User>
  startedAt: Scalars['String']
  submittedAt?: Maybe<Scalars['String']>
}

export enum UserChallengeStatus {
  Active = 'ACTIVE',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING',
  Review = 'REVIEW',
  Submitted = 'SUBMITTED'
}

type UserChallengeSubmission = {
  __typename?: 'UserChallengeSubmission'
  content?: Maybe<Scalars['String']>
  files?: Maybe<Array<Scalars['String']>>
}

type UserChallengeTeam = {
  __typename?: 'UserChallengeTeam'
  user?: Maybe<User>
  email: Scalars['String']
  status: UserChallengeTeamStatus
}

enum UserChallengeTeamStatus {
  Accepted = 'ACCEPTED',
  Invited = 'INVITED'
}

export type UserCohort = {
  __typename?: 'UserCohort'
  cohort: Cohort
  project?: Maybe<Project>
  rating: Scalars['String']
  status?: Maybe<CohortParticipantStatus>
  progress: Scalars['String']
}

type UserCompany = {
  __typename?: 'UserCompany'
  company: Company
  role?: Maybe<CompanyRole>
}

export type UserLanguageInput = {
  language: Scalars['String']
  proficiency: Scalars['String']
}

export type UserLanguages = {
  __typename?: 'UserLanguages'
  language: Scalars['String']
  proficiency: Scalars['String']
}

type UserMeta = {
  __typename?: 'UserMeta'
  agreement?: Maybe<UserMetaAgreement>
  banks?: Maybe<Array<Bank>>
  birthday?: Maybe<Scalars['String']>
  certifications?: Maybe<Array<UserCertification>>
  company?: Maybe<Scalars['String']>
  compliments?: Maybe<Array<Compliment>>
  customerID?: Maybe<Scalars['String']>
  feedbacks?: Maybe<Array<UserMetaFeedback>>
  gender?: Maybe<Scalars['String']>
  headline?: Maybe<Scalars['String']>
  incentives?: Maybe<UserMetaIncentives>
  interests?: Maybe<Array<Category>>
  languages?: Maybe<Array<UserLanguages>>
  nationality?: Maybe<Scalars['String']>
  otherInterests?: Maybe<Array<Category>>
  preferences?: Maybe<UserMetaPreferences>
  proficiencies?: Maybe<Array<UserProficiency>>
  ratings?: Maybe<Array<Scalars['Int']>>
  rating?: Maybe<UserMetaRating>
  residence?: Maybe<Scalars['String']>
  schools?: Maybe<Array<UserSchool>>
  showTour: Scalars['Boolean']
  skills?: Maybe<Array<UserSkill>>
  startDate?: Maybe<Scalars['String']>
  tools?: Maybe<Array<Category>>
  timezone: Scalars['String']
  viewedTour?: Maybe<Array<Scalars['String']>>
  website?: Maybe<Scalars['String']>
}

type UserMetaAgreement = {
  __typename?: 'UserMetaAgreement'
  conduct: Scalars['Boolean']
  mentor: Scalars['Boolean']
  privacy: Scalars['Boolean']
  website: Scalars['Boolean']
}

type UserMetaFeedback = {
  __typename?: 'UserMetaFeedback'
  feedback: Scalars['String']
  createdAt: Scalars['String']
}

type UserMetaIncentives = {
  __typename?: 'UserMetaIncentives'
  emailVerification: Scalars['Boolean']
  phoneVerification: Scalars['Boolean']
  referrals: Scalars['Boolean']
  sessions: Scalars['Boolean']
}

type UserMetaPreferences = {
  __typename?: 'UserMetaPreferences'
  experience?: Maybe<Array<Skill>>
  opportunity?: Maybe<Array<OpportunityType>>
  salary?: Maybe<Array<SalaryType>>
}

type UserMetaRating = {
  __typename?: 'UserMetaRating'
  cohorts: Scalars['String']
  projects: Scalars['String']
  total: Scalars['String']
}

type UserNotification = {
  __typename?: 'UserNotification'
  projects: Scalars['Boolean']
  challenges: Scalars['Boolean']
  community?: Maybe<Array<CommunityNotification>>
}

type UserPresence = {
  __typename?: 'UserPresence'
  behance?: Maybe<Scalars['String']>
  github?: Maybe<Scalars['String']>
  linkedin?: Maybe<Scalars['String']>
}

export type UserProficiency = {
  __typename?: 'UserProficiency'
  category: Category
  proficiency: Category
  rating: Scalars['String']
}

type UserReputation = {
  __typename?: 'UserReputation'
  challenges: Array<UserChallenge>
  session: UserReputationSession
  endorsements: Array<Endorsement>
  endorsementSkills: Array<Skill>
  projects: Array<Project>
  reputation: Scalars['Float']
}

type UserReputationSession = {
  __typename?: 'UserReputationSession'
  peer: Array<Session>
  expert: Array<Session>
}

export enum UserRole {
  Admin = 'ADMIN',
  Mentor = 'MENTOR',
  Professional = 'PROFESSIONAL',
  Student = 'STUDENT',
  Enterprise = 'ENTERPRISE'
}

type UserSchool = {
  __typename?: 'UserSchool'
  course?: Maybe<Field>
  email?: Maybe<Scalars['String']>
  emailSuffix?: Maybe<Scalars['String']>
  graduated?: Maybe<Scalars['String']>
  major?: Maybe<Degree>
  school?: Maybe<School>
  verified?: Maybe<Scalars['Boolean']>
}

type UserSchoolInput = {
  course?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
  emailSuffix?: Maybe<Scalars['String']>
  graduated?: Maybe<Scalars['String']>
  major: Scalars['ID']
  school: Scalars['ID']
  verified?: Maybe<Scalars['Boolean']>
}

export type UserSkill = {
  __typename?: 'UserSkill'
  skill: Skill
  level?: Maybe<SkillRank>
  points?: Maybe<Scalars['Int']>
  beginner: Scalars['Boolean']
  proficient: Scalars['Boolean']
  skilled: Scalars['Boolean']
}

enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type VotePollInput = {
  post: Scalars['ID']
  answer: Scalars['ID']
}

type Voucher = {
  __typename?: 'Voucher'
  id: Scalars['ID']
  code: Scalars['String']
  value: Scalars['Int']
  isAvailable: Scalars['Boolean']
  createdAt: DateItem
}

type WorkExperience = {
  __typename?: 'WorkExperience'
  company?: Maybe<Company>
  description?: Maybe<Scalars['String']>
  endDate?: Maybe<Scalars['String']>
  isCurrent: Scalars['Boolean']
  name: Scalars['String']
  logo?: Maybe<Scalars['String']>
  startDate: Scalars['String']
  role: Scalars['String']
}

export type XpHistory = {
  __typename?: 'XPHistory'
  id: Scalars['ID']
  user: User
  project?: Maybe<Project>
  challenge?: Maybe<UserChallenge>
  xp: Scalars['Int']
  details: Scalars['String']
  createdAt: DateItem
}
