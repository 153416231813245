import { ReactNode } from 'react'
import { atom } from 'recoil'

export const drawerContent = atom({
  default: null,
  key: 'drawerContent'
})

export const drawerV2Content = atom({
  default: undefined,
  key: 'drawerV2open'
})

export interface DrawerInterface {
  open: boolean
  title: string
  loading?: boolean
  closeIcon?: ReactNode
}

export const drawer = atom<DrawerInterface>({
  default: {
    open: false,
    title: ''
  },
  key: 'drawer'
})

export const drawerBodyStyle = atom({
  default: {},
  key: 'drawerBodyStyle'
})

export const drawerHeaderStyle = atom({
  default: {},
  key: 'drawerHeaderStyle'
})

export interface ICampaignDrawerState {
  visible: boolean
  hasCampaign: boolean
}

export const campaignDrawerState = atom<ICampaignDrawerState>({
  default: {
    visible: false,
    hasCampaign: false
  },
  key: 'campaignDrawer'
})
