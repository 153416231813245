import Cookies from 'js-cookie'
import { NextPageContext } from 'next'
import { parseCookies } from 'nookies'
import { UserRole } from 'src/types/shared/graphql'

export const REDIRECT_KEY = 'redirect'

export const withAuth = (context: NextPageContext) => {
  let token: string

  if (process.browser) {
    token = Cookies.get('qureos_token')
  } else {
    token = parseCookies(context).qureos_token
  }

  return {
    loggedIn: !!token
  }
}

export const isLoggedIn = () => {
  const token: string = Cookies.get('qureos_token')

  return !!token
}

export const login = (token: string) => {
  Cookies.set('qureos_token', token)
  Cookies.set('auth_token', token)
}

export const getLoginToken = (): string => {
  const token = Cookies.get('auth_token')
  return token
}

export const setRole = (role: UserRole) => {
  Cookies.set('role', role)
}

export const logout = () => {
  localStorage.clear()
  Cookies.remove('google_token')
  Cookies.remove('qureos_token')
  Cookies.remove('auth_token')
  Cookies.remove('linkedin_token')
  Cookies.remove('qureos_admin_token')
}

export const setAdminCookie = (token: string): void => {
  Cookies.set('qureos_admin_token', token)
}

export const getAdminCookie = (): string | '' => {
  const token: string | '' = Cookies.get('qureos_admin_token')
  return token
}

export const removeAdminCookie = (): void => {
  Cookies.remove('qureos_admin_token')
}

export const loginRedirect = (action: 'set' | 'remove' | 'get', url = '') => {
  switch (action) {
    case 'set':
      Cookies.set(REDIRECT_KEY, url)
      break
    case 'remove':
      Cookies.remove(REDIRECT_KEY)
      break
    case 'get':
      return Cookies.get(REDIRECT_KEY)
  }
}

export const isPrivateLoggedIn = (): boolean => {
  const token: string = Cookies.get('qureos_admin_token')

  return !!token
}

export const withJobRedirect = (context: NextPageContext) => {
  let url = ''

  if (process.browser) {
    url = Cookies.get(REDIRECT_KEY)
  } else {
    url = parseCookies(context).redirect
  }

  return {
    url
  }
}

// temporary key
export const setCustom = (): void => {
  Cookies.set('custom', 'true')
}

export const isCustom = (): boolean => {
  let result = false

  const custom = Cookies.get('custom')

  if (custom && custom === 'true') {
    result = true
  }

  return result
}

export const payment = (id?: string): string => {
  if (id) {
    Cookies.set('default_payment', id)

    return id
  }

  if (!id) {
    return Cookies.get('default_payment')
  }
}
