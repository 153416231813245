import { createTheme } from './utils'
export const defaultPageThemeNew = createTheme({
  qureosPrimary: '#3461ff', //blue-800
  qureosPrimaryLight: '#2563eb', //blue-600
  qureosPrimaryDark: '#2B23CA', //blue-900
  qureosSecondary: '#34A5F3', //qureosBlue
  qureosSecondaryLight: '#3b82f6', //blue-500
  qureosSecondaryDark: '#1d4ed8', //blue-700
  qureosPrimaryAccent: '#0a1a70'
})

export const defaultPageTheme = createTheme({
  qureosPrimary: '#3461ff', //blue-800
  qureosPrimaryLight: '#3b82f630', //blue-600
  qureosPrimaryDark: '#2B23CA', //blue-900
  qureosSecondary: '#34A5F3', //qureosBlue
  qureosSecondaryLight: '#3b82f6', //blue-500
  qureosSecondaryDark: '#1d4ed8', //blue-700
  qureosPrimaryAccent: '#0a1a70'
})
