import { transformId } from '@/utils/static-helpers/transform-response'
import axios from 'axios'
import Cookies from 'js-cookie'

const apiClient = axios.create({
  baseURL: process.env.API_URL,
  timeout: 30000
})

apiClient.interceptors.request.use(config => {
  if (Cookies.get('auth_token')) {
    config.headers['Authorization'] = `Bearer ${Cookies.get('auth_token')}`
  }
  return config
})

apiClient.interceptors.response.use(response => {
  if (response.data !== null) {
    response.data = transformId(response.data)
  }

  return response
})

export default apiClient
