import { user as userAtom } from '@qureos/atoms'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getCompanyUsage } from 'src/services/company/subscriptions'
import CompanyUsageInterface from 'src/services/company/subscriptions/interfaces/company-usage.interface'
import { User, UserRole } from 'src/types/shared/graphql'

export interface CreditUsageData extends CompanyUsageInterface {
  hasAvailableCredits: boolean
  remainingCredits: number
  planId?: string
}

interface CreditContextType {
  usage: CreditUsageData
  loading: boolean
  refetch: () => Promise<void>
}

export const CreditsContext = createContext<CreditContextType>({
  usage: {} as CreditUsageData,
  loading: false,
  refetch: async () => new Promise(() => {})
})

export const CreditsProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [usage, setUsage] = useState<CreditUsageData>({} as CreditUsageData)
  const [loading, setLoading] = useState<boolean>(false)
  const user = useRecoilValue<User>(userAtom)

  const getUsageInfo = async () => {
    if (loading) return

    setLoading(true)
    try {
      const response = await getCompanyUsage()
      const hasAvailableCredits = response.totalCredits > response.usedCredits
      const usageData = {
        ...response,
        hasAvailableCredits,
        remainingCredits: hasAvailableCredits
          ? response.totalCredits - response.usedCredits
          : 0,
        planId: response.ongoingSubscription?._id
      }
      setUsage(usageData)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (user && user.role === UserRole.Professional) {
      getUsageInfo()
    }
  }, [user])

  const contextValue = useMemo(
    () => ({ usage, loading, refetch: getUsageInfo }),
    [usage, loading, getUsageInfo]
  )

  return (
    <CreditsContext.Provider value={contextValue}>
      {children}
    </CreditsContext.Provider>
  )
}
