import { user as userAtom } from '@qureos/atoms'
import { Workspace } from '@qureos/types'
import { createContext, useEffect, useMemo, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { getCompanyWorkspaces } from 'src/services/workspaces'
import { User, UserRole } from 'src/types/shared/graphql'

interface WorkspaceContextType {
  workspaces: Workspace[]
  loading: boolean
  error: string | null
  activeWorkspace: Workspace
  setActiveWorkspace: (workspace: Workspace | undefined) => void
}

export const WorkspaceContext = createContext<WorkspaceContextType>({
  workspaces: [],
  loading: true,
  error: null,
  activeWorkspace: {} as Workspace,
  setActiveWorkspace: () => {}
})

export const WorkspaceProvider = ({
  children
}: {
  children: React.ReactNode
}) => {
  const [workspaces, setWorkspaces] = useState<Workspace[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [workspace, setWorkspace] = useState<Workspace | undefined>(undefined)
  const user = useRecoilValue<User>(userAtom)

  useEffect(() => {
    const fetchWorkspaces = async () => {
      try {
        const response = await getCompanyWorkspaces({
          offset: 0,
          limit: 1000
        })

        setWorkspaces(response.data)

        if (!workspace) {
          setWorkspace(response.data[0])
        }
      } catch (err) {
        setError(err.message)
      } finally {
        setLoading(false)
      }
    }

    if (user && user.role === UserRole.Professional) {
      fetchWorkspaces()
    }
  }, [user])

  const setActiveWorkspace = (workspace: Workspace) => setWorkspace(workspace)

  const contextValue = useMemo(
    () => ({
      workspaces,
      loading,
      error,
      setActiveWorkspace,
      activeWorkspace: workspace
    }),
    [workspaces, loading, error, workspace]
  )

  return (
    <WorkspaceContext.Provider value={contextValue}>
      {children}
    </WorkspaceContext.Provider>
  )
}
