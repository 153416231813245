export const FB_PIXEL_ID =
  process.env.FACEBOOK_PIXEL_ID || process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID

export const PIXEL_ENVS = ['production', 'stg', 'staging']
declare global {
  interface Window {
    fbq: any
  }
}

export const pageview = (): void => {
  window.fbq('track', 'PageView')
}

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const event = (name: string, options = {}): void => {
  window.fbq('track', name, options)
}

export const customEvent = (name: string, options = {}): void => {
  window.fbq('trackCustom', name, options)
}
