export function applyTheme(theme: ICreateThemeReturn): void {
  const root = document.documentElement
  Object.keys(theme).forEach(cssVar => {
    root.style.setProperty(cssVar, theme[cssVar])
  })
}

interface ICreateTheme {
  qureosPrimary: string
  qureosPrimaryLight: string
  qureosPrimaryDark: string
  qureosSecondary: string
  qureosSecondaryLight: string
  qureosSecondaryDark: string
  qureosPrimaryAccent: string
  qureosSecondaryBrand?: string
}

interface ICreateThemeReturn {
  '--theme-primary': string
  '--theme-primary-light': string
  '--theme-primary-dark': string
  '--theme-secondary': string
  '--theme-secondary-light': string
  '--theme-secondary-dark': string
  '--theme-primary-accent': string
  '--theme-secondary-brand': string
}

export const createTheme = ({
  qureosPrimary = '#0E9F6E',
  qureosPrimaryLight = '2563eb',
  qureosPrimaryDark = '#2B23CA',
  qureosSecondary = '#34A5F3',
  qureosSecondaryLight = '#3b82f6',
  qureosSecondaryDark = '#1d4ed8',
  qureosPrimaryAccent = '#0a1a70',
  qureosSecondaryBrand = '#F5AE06'
}: ICreateTheme): ICreateThemeReturn => {
  return {
    '--theme-primary': qureosPrimary,
    '--theme-primary-light': qureosPrimaryLight,
    '--theme-primary-dark': qureosPrimaryDark,
    '--theme-secondary': qureosSecondary,
    '--theme-secondary-light': qureosSecondaryLight,
    '--theme-secondary-dark': qureosSecondaryDark,
    '--theme-primary-accent': qureosPrimaryAccent,
    '--theme-secondary-brand': qureosSecondaryBrand
  }
}
