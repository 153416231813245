import { FC, ReactNode, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { user as userAtom } from 'src/atoms'
import { User } from 'src/types/shared/graphql'
import ReactGA from 'react-ga'
import { useRouter } from 'next/router'

const GA_TRACKING = 'UA-138226813-2'

ReactGA.initialize(GA_TRACKING)

export const GoogleAnalyticsProvider: FC<{ children?: ReactNode }> = ({
  children
}) => {
  const profile = useRecoilValue<User>(userAtom)
  const router = useRouter()

  useEffect(() => {
    if (profile) {
      ReactGA.set({
        userId: profile.id
      })
    }
  }, [profile])

  useEffect(() => {
    ReactGA.pageview(router.pathname)
  })

  return <>{children}</>
}
