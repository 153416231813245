import { useRecoilValue } from 'recoil'
import { User, UserRole } from 'src/types/shared/graphql'
import { user as userAtom } from 'src/atoms'
import { FC, ReactNode, useEffect } from 'react'
import { applyTheme } from './utils'
import { VfsTheme, apprenticeTheme, corporateTheme, mentorTheme } from './base'
import { defaultPageTheme } from './default'
import { useRouter } from 'next/router'

const convertToObj = arr => {
  return arr.reduce((a, v) => ({ ...a, [v]: v }), {})
}
const mentorPaths = [
  '/mentor/signin',
  '/mentor/signup',
  '/mentor/verify',
  '/mentor/forgot-password',
  '/mentor/change-password'
]
const corporatePaths = [
  '/corporate/login',
  '/corporate/signup',
  '/corporate/verify',
  '/corporate/forgot-password',
  '/corporate/change-password'
]
const paths = {
  mentor: convertToObj(mentorPaths),
  corporate: convertToObj(corporatePaths)
}

export const ThemeProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const user = useRecoilValue<User>(userAtom)
  const isVfsServiceEnable = user?.defaultCompany?.isVfs || false
  const router = useRouter()
  useEffect(() => {
    if (user?.role === UserRole.Student) {
      applyTheme(apprenticeTheme)
    } else if (
      user?.role === UserRole.Mentor ||
      paths.mentor[router.pathname]
    ) {
      applyTheme(mentorTheme)
    } else if (user?.role === UserRole.Professional && isVfsServiceEnable) {
      applyTheme(VfsTheme)
    } else if (
      user?.role === UserRole.Professional ||
      paths.corporate[router.pathname]
    ) {
      applyTheme(corporateTheme)
    } else if (typeof user?.role === 'undefined') {
      applyTheme(defaultPageTheme)
    }
  }, [user])

  return <>{children}</>
}

export default ThemeProvider
