import Script from 'next/script'
import { FC, ReactNode } from 'react'
import { useRecoilValue } from 'recoil'
import { user as userAtom } from 'src/atoms'
import { User } from 'src/types/shared/graphql'

const allowedEmails = [
  'sharjeel@qureos.com',
  'alex+iris@qureos.com',
  'albert+demo@qureos.com',
  'alzaben@qureos.com'
]

export const HotjarProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const profile = useRecoilValue<User>(userAtom)

  if (profile && allowedEmails.includes(profile.email)) {
    return (
      <>
        <Script
          id="detect-user"
          dangerouslySetInnerHTML={{
            __html: `
          (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:2666929,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
          })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
          `
          }}
        />
        {children}
      </>
    )
  }
  return <>{children}</>
}
