import { set } from 'lodash'
import apiClient from 'src/services/base'
import CompanyUsageInterface from './interfaces/company-usage.interface'

export const createCompanySubscription = (
  priceId: string,
  bundleId: string,
  bundleQuantity: number
): Promise<any> => {
  const obj = {
    priceId
  }
  if (bundleId && bundleQuantity) {
    set(obj, 'creditBundle', {
      bundleId,
      quantity: bundleQuantity
    })
  }
  return apiClient
    .post('/companies/subscriptions', {
      ...obj
    })
    .then(resp => resp.data)
}

export const upgradeCompanySubscription = (
  priceId: string,
  isPreview: boolean,
  bundleId: string,
  bundleQuantity: number
): Promise<any> => {
  const obj = {
    priceId,
    isPreview
  }
  if (bundleId && bundleQuantity) {
    set(obj, 'creditBundle', {
      bundleId,
      quantity: bundleQuantity
    })
  }
  return apiClient
    .patch('/companies/subscriptions/upgrade', {
      ...obj
    })
    .then(resp => resp.data)
}

export const downgradeCompanySubscription = (priceId: string): Promise<any> => {
  return apiClient
    .patch('/companies/subscriptions/degrade', { priceId })
    .then(resp => resp.data)
}

export const getCompanyUsage = (): Promise<CompanyUsageInterface> => {
  return apiClient.get('/companies/subscriptions/usage').then(resp => resp.data)
}

export const getCompanySubscription = (params: {
  status: string[]
  companyId?: string
}): Promise<any> => {
  const queryParams = new URLSearchParams()

  Object.keys(params).forEach(key => {
    const queryValue = params[key].toString()
    if (key === 'status') {
      params[key].forEach(status => queryParams.append(key + '[]', status))
    } else {
      queryParams.append(key, queryValue.toString())
    }
  })

  return apiClient
    .get(`/companies/subscriptions?${queryParams.toString()}`)
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const getSubscriptionPlansAndPrice = (id: string): Promise<any> => {
  return apiClient
    .get(`/companies/subscriptions/plans/prices/${id}`)
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const deleteCompanySubscription = (
  subscriptionId: string
): Promise<any> => {
  return apiClient
    .delete(`/companies/subscriptions/${subscriptionId}`)
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const restoreCompanySubscription = (): Promise<any> => {
  return apiClient
    .post(`/companies/subscriptions/restore`)
    .then(resp => resp.data)
    .catch(err => err.response.data)
}

export const getSubscriptionInvoice = (params: {
  page: number
  limit: number
}): Promise<any> => {
  const queryParams = new URLSearchParams()
  Object.keys(params).forEach(key => {
    const queryValue = params[key].toString()
    queryParams.append(key, queryValue.toString())
  })

  return apiClient
    .get(`/companies/invoices?${queryParams.toString()}`)
    .then(resp => resp.data)
    .catch((err: any) => err.response.data)
}

export const getSubscriptionLatestInvoice = (id: string): Promise<any> => {
  return apiClient
    .get(`/companies/subscriptions/${id}/latest-invoice`)
    .then(resp => resp.data)
    .catch((err: any) => err.response.data)
}

export const getCompanybundles = (): Promise<any> => {
  return apiClient
    .get(`/companies/credit-bundles`)
    .then(resp => resp.data)
    .catch((err: any) => err.response.data)
}

export const buyCompanybundles = (data): Promise<any> => {
  return apiClient
    .post(`/companies/credit-bundles/buy`, { ...data })
    .then(resp => resp.data)
    .catch((err: any) => err.response.data)
}

export const getCompanybundleData = (id: string): Promise<any> => {
  return apiClient
    .get(`/companies/credit-bundles/${id}`)
    .then(resp => resp.data)
    .catch((err: any) => err.response.data)
}
