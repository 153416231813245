import { atom } from 'recoil'

export const user = atom({
  default: undefined,
  key: 'user'
})

export const updateProfile = atom({
  default: false,
  key: 'updateProfile'
})

export const userTzOffset = atom({
  default: new Date().getTimezoneOffset(),
  key: 'userTzOffset'
})

export const userForm = atom({
  default: undefined,
  key: 'userForm'
})

export const mentors = atom({
  default: undefined,
  key: 'mentors'
})

export const following = atom({
  default: undefined,
  key: 'following'
})

export const followers = atom({
  default: undefined,
  key: 'followers'
})
export const userSetting = atom({
  default: undefined,
  key: 'userSetting'
})

export const userV3 = atom({
  default: undefined,
  key: 'userv3'
})

export const apprentices = atom({
  default: undefined,
  key: 'apprentices'
})
