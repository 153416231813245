import { XMarkIcon } from '@heroicons/react/24/outline'
import {
  drawer as drawerAtom,
  drawerBodyStyle,
  drawerHeaderStyle,
  drawerV2Content,
  DrawerInterface
} from 'src/atoms'
import { Drawer as AntDrawer } from 'antd'
import React, { cloneElement, FC } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'

export const Drawer: FC = () => {
  const [drawer, setDrawer] = useRecoilState<DrawerInterface>(drawerAtom)
  const content = useRecoilValue<JSX.Element>(drawerV2Content)
  const drawerBodyStyleValue =
    useRecoilValue<React.CSSProperties>(drawerBodyStyle)
  const drawerHeaderStyleValue =
    useRecoilValue<React.CSSProperties>(drawerHeaderStyle)
  const onClose = () => {
    setDrawer({ ...drawer, open: false, closeIcon: null })
  }

  return (
    <AntDrawer
      title={drawer.title}
      // extra={closeButtonOnRight}
      closable={true}
      placement="right"
      destroyOnClose={true}
      closeIcon={
        drawer.closeIcon || <XMarkIcon className="text-gray-800 w-6" />
      }
      visible={drawer.open}
      contentWrapperStyle={{
        width: '100%',
        maxWidth: '600px'
      }}
      headerStyle={drawerHeaderStyleValue}
      bodyStyle={drawerBodyStyleValue}
      onClose={onClose}
    >
      {content && cloneElement(content)}
    </AntDrawer>
  )
}
