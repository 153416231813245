import { Workspace } from 'src/types/workspace'
import apiClient from './base'

export const getCompanyWorkspaces = async (
  filter: {
    offset: number
    limit: number
    title?: string
  },
  jwtToken?: string
) => {
  const query = new URLSearchParams()
  if (filter.title) {
    query.append('title', filter.title)
  }
  query.append('offset', String(filter.offset))
  query.append('limit', String(filter.limit))
  const resp = await apiClient.get<{ data: Workspace[]; count: number }>(
    `/workspaces?${query.toString()}`,
    { headers: jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {} }
  )
  return resp.data
}

export const getWorkspace = async (workspaceId: string) => {
  const resp = await apiClient.get<Workspace>(`/workspaces/${workspaceId}`)
  return resp.data
}

export const createWorkspace = async (payload: { title: string }) => {
  const resp = await apiClient.post<Workspace>('/workspaces', payload)
  return resp.data
}

export const updateWorkspace = async (
  workspaceId: string,
  payload: { title: string }
) => {
  const resp = await apiClient.patch<Workspace>(
    `/workspaces/${workspaceId}`,
    payload
  )
  return resp.data
}

export const deleteWorkspace = async (workspaceId: string) => {
  const resp = await apiClient.delete<Workspace>(`/workspaces/${workspaceId}`)
  return resp.data
}
