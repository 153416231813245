import apiClient from 'src/services/base'
import { UserAnalyticsEvent } from 'src/types/userAnalytics'

export const captureVisit = (path: string, url: string): any => {
  return apiClient.post('/user-analytics/capture-visit', { path, url })
}

export const captureEvent = (event: UserAnalyticsEvent): any => {
  return apiClient.post('/user-analytics/capture-event', event)
}
