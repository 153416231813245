import { createTheme } from './utils'
export const apprenticeThemeNew = createTheme({
  qureosPrimary: '#2667FF',
  qureosPrimaryLight: 'rgba(38, 103, 255, 0.05)',
  qureosPrimaryDark: '#2B23CA', //blue-900
  qureosSecondary: '#34A5F3', //qureosBlue
  qureosSecondaryLight: '#3b82f6', //blue-500
  qureosSecondaryDark: '#1d4ed8', //blue-700
  qureosPrimaryAccent: '#0a1a70',
  qureosSecondaryBrand: '#F5AE06'
})

export const corporateThemeNew = createTheme({
  qureosPrimary: '#0E9F6E',
  qureosPrimaryLight: 'rgba(10, 26, 112, 0.1)',
  qureosPrimaryDark: '#166534', //green-800
  qureosSecondary: '#22c55e', //green-500
  qureosSecondaryLight: '#3b82f6', //blue-500
  qureosSecondaryDark: '##16a34a', //green-600
  qureosPrimaryAccent: '#0a1a70'
})

export const mentorThemeNew = createTheme({
  qureosPrimary: '#8B31FF',
  qureosPrimaryLight: 'rgba(139, 49, 255, 0.05)', //purple-500
  qureosPrimaryDark: '#6b21a8', //purple-800
  qureosSecondary: '#B443D0', //qureosPurple
  qureosSecondaryLight: '#2B23CA', //blue-900
  qureosSecondaryDark: '#581c87', //purple-900
  qureosPrimaryAccent: '#0a1a70'
})

export const apprenticeTheme = createTheme({
  qureosPrimary: '#3461ff', //blue-800
  qureosPrimaryLight: '#34A5F350', //blue-600
  qureosPrimaryDark: '#2B23CA', //blue-900
  qureosSecondary: '#3461ff', //qureosBlue
  qureosSecondaryLight: '#2563eb50', //blue-500
  qureosSecondaryDark: '#1d4ed8', //blue-700
  qureosPrimaryAccent: '#0a1a70',
  qureosSecondaryBrand: '#F5AE06'
})

export const corporateTheme = createTheme({
  qureosPrimary: '#0E9F6E', //corporate-green
  qureosPrimaryLight: '#00d58e50', //qureosBrightGreen
  qureosPrimaryDark: '#166534', //green-800
  qureosSecondary: '#1DBE65', //green-500
  qureosSecondaryLight: '#3b82f6', //blue-500
  qureosSecondaryDark: '##16a34a', //green-600
  qureosPrimaryAccent: '#0a1a70'
})

export const VfsTheme = createTheme({
  qureosPrimary: '#FF5A1F', // business-orange
  qureosPrimaryLight: '#FFF8F1', //light-orange
  qureosPrimaryDark: '#D24B17',
  qureosSecondary: '#FBE9DC',
  qureosSecondaryLight: '#F0DCCF',
  qureosSecondaryDark: '##A63A11',
  qureosPrimaryAccent: '#0a1a70'
})

export const mentorTheme = createTheme({
  qureosPrimary: '#7e22ce', //purple-700
  qureosPrimaryLight: '#6D28D950', //purple-500
  qureosPrimaryDark: '#6b21a8', //purple-800
  qureosSecondary: '#7e22ce', //qureosPurple
  qureosSecondaryLight: '#2B23CA', //blue-900
  qureosSecondaryDark: '#581c87', //purple-900
  qureosPrimaryAccent: '#0a1a70'
})
